import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AgentModel } from 'src/app/core/clients/generated/client';
import { PlanningDeskRequestType, RecommendationType } from '../../constants/planning-desk-request.constant';
import { PlanningDeskGeneralInfoFormGroup } from '../../models/planning-desk.model';
import { GenericRequestOption } from '../../../shared/models/workflow.models';
import { PortfolioAnalysisCategoryEnum } from '../../enums/portfolio-analysis-category.enum';
import { RecommendationTypeEnum } from '../../enums/recommendation-type.enum';

@Component({
  selector: 'app-general-info-step',
  templateUrl: './general-info-step.component.html'
})
export class GeneralInfoStepComponent implements OnInit, OnDestroy {
  @Input() parentForm?: PlanningDeskGeneralInfoFormGroup;
  @Input() advisorList?: AgentModel[];
  planningDeskRequestType = PlanningDeskRequestType;
  recommendationType = RecommendationType;
  portfolioAnalysisCategoryEnum = PortfolioAnalysisCategoryEnum;
  requestTypeSub?: Subscription;
  userRecommendationSub?: Subscription;
  minStartDate = new Date();

  constructor () { }

  ngOnInit(): void {
    this.checkRequestTypeValueChange();
    this.checkUserRecommendationValueChange();
    // init. minStartdate to 2 days from Now for the date picker
    this.minStartDate.setDate(this.minStartDate.getDate() + 2);
    this.parentForm?.controls.nextAppointmentDate.setValue(this.minStartDate);
  }

  ngOnDestroy(): void {
    this.requestTypeSub?.unsubscribe();
  }

  private checkRequestTypeValueChange(): void {
    this.parentForm?.controls.recommendationType.patchValue(this.recommendationType[ 0 ]);
    this.requestTypeSub = this.parentForm?.controls.requestType.valueChanges.subscribe({
      next: (value: GenericRequestOption) => {
        if (value.value === PortfolioAnalysisCategoryEnum.analysisAndProposal) {
          this.parentForm?.controls.recommendationType.enable();
        } else {
          this.parentForm?.controls.recommendationType.patchValue(this.recommendationType[ 0 ]);
          this.parentForm?.controls.recommendationType.disable();
          this.parentForm?.controls.notes.clearValidators();
          this.parentForm?.controls.notes.updateValueAndValidity({ onlySelf: false, emitEvent: false });
        }
      }
    });
  }

  private checkUserRecommendationValueChange(): void {
    this.userRecommendationSub = this.parentForm?.controls.recommendationType.valueChanges.subscribe({
      next: (res: GenericRequestOption) => {
        if (res.value === RecommendationTypeEnum.user) {
          this.parentForm?.controls.notes.setValidators([ Validators.required ]);
        } else {
          this.parentForm?.controls.notes.clearValidators();
        }
        this.parentForm?.controls.notes.updateValueAndValidity({ onlySelf: false, emitEvent: false });
      }
    });
  }
}
