import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { InvestmentModelComponent } from './components/investment-model/investment-model.component';
import { InvestmentDetailsModalComponent } from './modals/investment-details-modal/investment-details-modal.component';
import { InvestmentsPageComponent } from './pages/investments-page.component';


@NgModule({
  declarations: [
    InvestmentsPageComponent,
    InvestmentModelComponent,
    InvestmentDetailsModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
  ]
})
export class InvestmentModule { }
