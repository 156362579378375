import { Component, Input, OnInit } from '@angular/core';
import { fadeIn, fadeInOut } from '../../constants/animations';

@Component({
  selector: 'app-message-notification',
  templateUrl: './message-notification.component.html',
  animations: [fadeIn, fadeInOut],
  styles: [
    `
      :host {
        display: block;
      }
    `
  ]
})
/** This can be extended and reused for more complex scenarios. Ex: Self-remove after set time, dismissable, etc. */
export class MessageNotificationComponent implements OnInit {
  private classList = {
    info: 'border-blue-lighter bg-blue-lightest text-gray-dark',
    alert: 'border-orange-lighter bg-orange-lightest text-orange',
    error: 'border-red-lighter bg-red-lightest text-red',
  };
  private iconList = {
    info: 'info',
    alert: 'warning',
    error: 'warning'
  };
  iconClass = '';
  panelIcon = '';
  panelClass = '';
  @Input() panelType: 'info' | 'alert' | 'error' = 'info';
  @Input() showPanelIcon?:boolean = true;

  ngOnInit(): void {
    this.setPanelContent();
  }

  private setPanelContent(): void {
    switch (this.panelType) {
      case 'alert':
        this.iconClass = 'text-orange';
        this.panelClass = this.classList.alert;
        this.panelIcon = this.iconList.alert;
        break;

      case 'error':
        this.iconClass = 'text-red';
        this.panelClass = this.classList.error;
        this.panelIcon = this.iconList.error;
        break;

      default:
        this.iconClass = 'text-blue-light';
        this.panelClass = this.classList.info;
        this.panelIcon = this.iconList.info;
        break;
    }
  }
}