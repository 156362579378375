import { DeliveryMethodEnum } from '../enums/delivery-methods.enum';
import { TradeFrequencyEnum } from '../enums/trade-frequency.enum';
import { WireTransferRecipientEnum } from '../enums/wire-transfer-recipient.enum';
import { GenericRequestOption } from '../models/workflow.models';

export const AllocationFrequencies: GenericRequestOption[] = [
  { value: TradeFrequencyEnum.oneTime, name: 'One Time' },
  { value: TradeFrequencyEnum.weekly, name: 'Weekly' },
  { value: TradeFrequencyEnum.biWeekly, name: 'Every other week' },
  { value: TradeFrequencyEnum.monthly, name: 'Monthly' },
  { value: TradeFrequencyEnum.lastBizDayOfMonth, name: 'Last business day of month' },
  { value: TradeFrequencyEnum.quarterly, name: 'Quarterly' },
  { value: TradeFrequencyEnum.biAnnually, name: 'Every six months' },
  { value: TradeFrequencyEnum.annually, name: 'Annually' },
  { value: TradeFrequencyEnum.everyTwoWeeks, name: 'Every two weeks' },
];

export const DeliveryMethods: GenericRequestOption[] = [
  { value: DeliveryMethodEnum.ach, name: 'ACH' },
  { value: DeliveryMethodEnum.check, name: 'Check' },
  { value: DeliveryMethodEnum.wireTransfer, name: 'Wire Transfer' },
  { value: DeliveryMethodEnum.leaveInCash, name: 'Leave in Cash for Outgoing Transfer' },
  { value: DeliveryMethodEnum.journalCash, name: 'Journal Cash to Receiving Account #' },
  { value: DeliveryMethodEnum.moveToRetail, name: 'Move to Retail' },
];

export const WireTransferRecipient: GenericRequestOption[] = [
  {
    name: 'Client',
    value: WireTransferRecipientEnum.client,
  },
  {
    name: 'Third Party',
    value: WireTransferRecipientEnum.thirdParty,
  }
];