import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamsPageComponent } from './pages/teams/teams-page.component';
import { PlayersPageComponent } from './pages/players/players-page.component';
import { PlayerListComponent } from './components/playerlist/playerlist.component';
import { TeamSelectorComponent } from './components/teamselector/teamselector.component';
import { PlayerdetailComponent } from './components/playerdetail/playerdetail.component';
import { CoreModule } from 'src/app/core/core.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [TeamsPageComponent, PlayersPageComponent, PlayerListComponent, TeamSelectorComponent, PlayerdetailComponent],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    RouterModule
  ]
})
export class ExampleModule { }
