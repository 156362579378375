import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/auth/authentication.service';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { ConfirmationModalData } from 'src/app/shared/modals/confirmation-modal/modal-data.models';
import { Permission } from 'src/app/core/clients/generated/client';

@Component({
  selector: 'app-user-navigation',
  templateUrl: './user-navigation.component.html',
})
export class UserNavigationComponent {

  perm = Permission;

  constructor(
    private auth: AuthenticationService,
    private dialog: MatDialog,
    private router: Router,
    private snackbarService: SnackbarService,
  ) { }

  logout(): void {
    this.dialog.open<ConfirmationModalComponent, ConfirmationModalData>(ConfirmationModalComponent, {
      data: {
        message: 'Are you sure you want to log out?',
        isWarning: true,
        confirmationButtonText: 'Log Out'
      }
    }).afterClosed()
      .subscribe(res => {
        if (res) {
          this.auth.logout();
          this.snackbarService.openSnackbar('You have successfully logged out.', ToastClassEnum.success, 7, 'top');
          this.router.navigateByUrl('/login');
        }
      });

  }
}
