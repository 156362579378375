// to help keep files small, we've attempted the practice of storing static arrays in their own files

import { Team } from '../models/team';

export const Teams:Team[] = [
  {
    Id: 1,
    TeamName: 'Nets',
    City: 'Brooklyn',
    Class: 'bg-gray-darker hover:bg-gray-darkest'
  },
  {
    Id: 2,
    TeamName: 'Lakers',
    City: 'Los Angeles',
    Class: 'bg-purple-dark hover:bg-purple-darker'
  }
];