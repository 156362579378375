import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SavedFiltersService {

  constructor() { }

  setSelectedAdvisors(advisorIds: number[]): void {
    localStorage.setItem('Ui.Accounts.SelectedAdvisors', JSON.stringify(advisorIds));
  }

  clearSelectedAdvisors():void{
    localStorage.removeItem('Ui.Accounts.SelectedAdvisors');
  }

  getSelectedAdvisors(): number[] {
    return JSON.parse(localStorage.getItem('Ui.Accounts.SelectedAdvisors') || '[]') as number[];
  }
}
