<div class="flex flex-col h-full w-full items-center justify-center fixed z-10">
    <div class="flex flex-col items-center flex-shrink-0 w-full">
        <img class="w-256 m-auto mt-20 h-96 " src="assets/images/logos/alphastar-logo-light-bg.svg">
        <div class="relative flex-shrink-0 w-360 min-h-384 max-w-9/10 card">
            <app-password-reset-requested class="h-full flex w-full overflow-auto"></app-password-reset-requested>
        </div>
        <app-copyright></app-copyright>
    </div>
</div>
<lottie-player @fadeIn class="fixed top-0 left-0 w-full h-auto z-0" src="assets/lottie/login-animate.json" background="transparent" loop autoplay>
</lottie-player>