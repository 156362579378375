import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { NavigationService } from './core/services/navigation.service';
import { fadeIn, fadeInOut, routerTransition, slideInOut } from './shared/constants/animations';
import { portalV2OptInCookieName } from './shared/constants/opt-in-cookie.constant';

@Component({
  animations: [fadeIn, fadeInOut, slideInOut, routerTransition],
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private navigationService:NavigationService,
    private titleService: Title,
    private cookieService:CookieService
  ){}

  ngOnInit(): void {
    // Instantiate navigation service in root so it recieves all route changes
    this.titleService.setTitle('Alphastar Portal');
    this.navigationService.listenForRouteChanges();
    if (this.cookieService.get(portalV2OptInCookieName).toLowerCase() === false.toString()) {
      window.location.href = environment.oldPortalURL;
    }
  }
}
