import { AccountSleeveModel, ClientModel, TDSchwabAccountNumberMigrationModel } from 'src/app/core/clients/generated/client';
import { FilterCheckbox } from '../../request-tracker/models/request-tracker-models';

export interface Account {
  Advisors: AccountAdvisor[];
  Clients: AccountClient[];
  Type: string | null;
  Model: string | null;
  Product: string | null;
  Value: number | null;
  CashValue: number | null;
  OpenedDate: Date | null;
  Fee: string | null;
  Status: string | null;
  IsInvestmentAccount: boolean;
  AccountNumber: string | null;
  Carrier: string | null;
  Custodian: string | null;
  CustodianId: number | null;
  InvestmentAccountId: number | null;
  InsuranceAccountId: number | null;
  Sleeves?: AccountSleeveModel[] | [];
  ShowSleeves: boolean | false;
  TDSchwabAccountNumbers?: TDSchwabAccountNumberMigrationModel[] | [];
}

export interface AccountAdvisor {
  AdvisorId: number;
  Crd: string;
  FirstName: string | null;
  LastName: string | null;
}

export interface AccountClient {
  FirstName: string | null;
  LastName: string | null;
  Id: number | null;
}

export interface PaperApplicationForm {
  Iar: number | null;
  CustodianID: number | null;
  Notes: string | null;
  Client: ClientModel | null;
  InvestmentAccountModelID: number | null;
  InvestmentAccountTypeID: number | null;
  Files: File[] | null
}

export interface PaperApplicationClientForm {
  FirstName: string | null;
  LastName: string | null;
  Email: string | null;
}

export class AccountsFilter {
  Status = '';
  Advisors: FilterCheckbox[] = [];
  Types: FilterCheckbox[] = [
    {
      Name: AccountTypeEnum.Investment,
      Value: AccountTypeEnum.Investment,
      Checked: false,
    },
    {
      Name: AccountTypeEnum.Insurance,
      Value: AccountTypeEnum.Insurance,
      Checked: false,
    }
  ];
}

export enum AccountTypeEnum {
  Investment = 'Investment',
  Insurance = 'Insurance'
}