import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionsService } from 'src/app/core/auth/permissions.service';
import { LoginClient, LoginFailureResponseModel, LoginFailureResponseType, LoginResponseType, PermissionsClient } from 'src/app/core/clients/generated/client';
import { fadeIn } from 'src/app/shared/constants/animations';

@Component({
  animations: [fadeIn],
  selector: 'app-login-form',
  templateUrl: './login-form.component.html'
})
export class LoginFormComponent implements OnInit {
  locked = false;
  lockedUntil?: Date;
  disabled = false;
  invalidCredentials = false;
  loginForm = this.fb.group({
    userNameOrEmail: this.fb.nonNullable.control('', Validators.required),
    password: this.fb.nonNullable.control('', Validators.required),
  });

  constructor(
    private fb: FormBuilder,
    private loginClient: LoginClient,
    private router: Router,
    private permissionsApiService: PermissionsClient,
    private permissionsService: PermissionsService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.checkInvalidQueryParam();
  }

  checkInvalidQueryParam(): void {
    const error = this.route.snapshot.queryParamMap.get('error');
    if (error === 'invalid') this.invalidCredentials = true;
  }

  onLoginSubmit(): void {
    this.loginForm.markAllAsTouched();
    if (this.loginForm.invalid) return;
    const username = this.loginForm.value.userNameOrEmail;
    const password = this.loginForm.value.password;
    if (username && password) {
      this.loginClient.login({
        userNameOrEmail: username,
        password: password,
      })
        .subscribe({
          next: (response) => {
            if (response.type === LoginResponseType.Success) {
              this.permissionsApiService.getUserPermissions().subscribe({
                next: (permissions) => this.permissionsService.setPermissions(permissions)
              })
                .add(() => this.router.navigateByUrl(''));
            } else if (response.type === LoginResponseType.RequiresMfaVerification) {
              this.router.navigateByUrl('/mfa-verification');
            } else if (response.type === LoginResponseType.RequiresMfaSetup) {
              this.router.navigateByUrl('/first-mfa-setup');
            }
          },
          error: (err: LoginFailureResponseModel) => {
            this.locked = err.type === LoginFailureResponseType.Locked;
            this.lockedUntil = err.lockedUntil;
            this.disabled = err.type === LoginFailureResponseType.Disabled;
            this.invalidCredentials = err.type === LoginFailureResponseType.InvalidCredentials;
          }
        });
    }
  }
}
