import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TeamsApiService } from 'src/app/core/http/teams-api.service';
import { Team } from '../../models/team';

@Component({
  selector: 'app-teamselector',
  templateUrl: './teamselector.component.html',
})
export class TeamSelectorComponent implements OnInit {
  Teams:Team[] = [];
  @Output() newTeamSelectedEvent = new EventEmitter<number>();

  constructor(private teamsApiService:TeamsApiService) { }

  ngOnInit(): void {
    this.teamsApiService.getTeams()
      .subscribe(teams => this.Teams = teams);
  }

  selectTeam(teamId:number):void{
    this.newTeamSelectedEvent.emit(teamId);
  }

}
