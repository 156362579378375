import { AcmMediaAsset } from 'src/app/core/clients/generated/client';

export interface InvestmentStrategyModalData {
  Name: string;
  LogoUrls: LogoEnum[];
  RiskTolerance?: RiskToleranceEnum;
  MoneyManager?: MoneyManagerEnum;
  ManagerFee?: ManagerFeeEnum;
  ModelMinimum?: ModelMinimumEnum;
  IsBetaShield: boolean,
  Assets: AcmMediaAsset[];
  Error: boolean;
  Description?: string;
  InvestmentLinks?: InvestmentLinkModel[];
}

export interface InvestmentStrategyModel {
  Id: string;
  Name: string;
  LogoUrl: LogoEnum;
  RiskTolerance: RiskToleranceEnum;
  MoneyManager: MoneyManagerEnum;
  ManagerFee: ManagerFeeEnum;
  ModelMinimum: ModelMinimumEnum;
  Tags: TagEnum[];
  Description?: string;
  InvestmentLinks?: InvestmentLinkModel[];
}

export interface InvestmentLinkModel {
  Title: string;
  Link: string;
}

export interface AdditionalOfferingModel {
  Id: string;
  Name: string;
  LogoUrls: LogoEnum[];
  Description: string;
  AdditionalDescription: string;
}

export interface InvestmentStrategyFilters {
  RiskTolerance: RiskToleranceEnum[],
  MoneyManager: MoneyManagerEnum[],
  ManagerFee: ManagerFeeEnum[],
  ModelMinimum: ModelMinimumEnum[],
  Tags: TagEnum[],
}

export interface RiskToleranceFilter {
  Name: RiskToleranceEnum;
  Checked: boolean;
}

export interface InvestmentModelCheckboxFilter {
  Name: string | number;
  Checked: boolean;
}

export enum LogoEnum {
  Alphastar = './assets/images/logos/alphastar-icon.svg',
  Vanguard = './assets/images/logos/vanguard-icon.svg',
  Russell = './assets/images/logos/russell-icon.svg',
  Twin = './assets/images/logos/generic-bank-icon.svg',
  Navellier = './assets/images/logos/navellier-icon.svg',
  VanHulzen = './assets/images/logos/vanhulzen-icon.png',
  Inspire = './assets/images/logos/inspire-icon.svg',
  Invesco = './assets/images/logos/invesco-icon.svg',
  Simon = './assets/images/logos/simon-icon.svg',
  Madrona = './assets/images/logos/madrona-icon.png',
  AlternativeInvestment = './assets/images/logos/alternative-investment-icon.svg',
  Evolv = './assets/images/logos/evolv-logo-square.png',
  Luma = './assets/images/logos/luma-logo-blue.svg',
}

export enum RiskToleranceEnum {
  Conservative = 'Conservative',
  ModeratelyConservative = 'Moderately Conservative',
  Moderate = 'Moderate',
  ModeratelyAggressive = 'Moderately Aggressive',
  Aggressive = 'Aggressive',
}

export enum ModelMinimumEnum {
  FiveHundred = 500,
  FiveThousand = 5000,
  TenThousand = 10000,
  FifteenThousand = 15000,
  TwentyFiveThousand = 25000,
  FiftyThousand = 50000,
  OneHundredThousand = 100000,
  TwoHundredFiftyThousand = 250000,
}

export enum ManagerFeeEnum {
  Zero = 0,
  Five = 5,
  Ten = 10,
  TwentyTwo = 22,
  TwentyFive = 25,
  Thirty = 30,
  Forty = 40,
  FortyThree = 43,
}

export enum MoneyManagerEnum {
  Alphastar = 'Alphastar Capital Management',
  Vanguard = 'Vanguard',
  Russell = 'Russell',
  Twin = 'TWIN',
  Navellier = 'Navellier',
  VanHulzen = 'Van Hulzen',
  Inspire = 'Inspire',
  Invesco = 'Invesco',
}

export enum TagEnum {
  Growth = 'Growth',
  RiskMitigation = 'Risk Mitigation',
  Income = 'Income',
  TaxEfficient = 'Tax Efficient',
  Preservation = 'Preservation',
}
