import { Component, Input } from '@angular/core';
import { AllocationFormGroup } from '../../../models/allocation-form.models';

@Component({
  selector: 'app-allocation-notes',
  templateUrl: './allocation-notes.component.html'
})
export class AllocationNotesComponent  {
  @Input() form?: AllocationFormGroup;
}
