import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-players-page',
  templateUrl: './players-page.component.html',
})
export class PlayersPageComponent implements OnInit {
  // The ! is a way of telling typescript that despite the fact we are not assigning this property a value. We know it will not be undefined.
  playerId!:number;
  isLoading = true;
  constructor(private activatedRoute:ActivatedRoute, private location:Location) { }

  ngOnInit(): void {
    // Getting  params. There's multiple ways this can be done, here is one example
    this.activatedRoute.paramMap
      .subscribe(params => {
        //the plus sign converts the value to a number
        this.playerId = +params.get('playerId')!;
        console.log(this.playerId);
        this.isLoading = false;
      });
  }

  goBack(){
    this.location.back();
  }

}
