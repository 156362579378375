import { Component, Input } from '@angular/core';
import { Dollar, Share } from '../../../constants/allocation-amount.constant';
import { Buy, Sell } from '../../../constants/trade-action.constants';
import { AllocationCustomHoldingSleeveFormArray } from '../../../models/allocation-form.models';

@Component({
  selector: 'app-allocation-summary',
  templateUrl: './allocation-summary.component.html'
})
export class AllocationSummaryComponent{
  @Input() customHoldingAllocations?:AllocationCustomHoldingSleeveFormArray;
  Buy = Buy;
  Sell = Sell;
  Share = Share;
  Dollar = Dollar;
}
