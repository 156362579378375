import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, map } from 'rxjs';
import { CustomRoute } from 'src/app/shared/models/custom-route.model';
import { PermissionsService } from '../auth/permissions.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  /* using a behavior subject as it will allow the navigation components to get the last value from this subject even if the component
   renders at a later time and the subsription occurs after the value is emitted https://www.learnrxjs.io/learn-rxjs/subjects/behaviorsubject */
  subNavigationItems$ = new BehaviorSubject<CustomRoute[]>([]);

  constructor (
    private router:Router,
    private activatedRoute:ActivatedRoute,
    private permissionService:PermissionsService
  ) {}

  listenForRouteChanges(): void {
    this.router.events.pipe(
      filter(event => {
        return event instanceof NavigationEnd;
      }),
      map(() => this.activatedRoute),
      map(this.getTopLevelRoute),
      filter(activatedRoute => activatedRoute.outlet === 'primary'))
      .subscribe(this.emitActiveRouteSubNavigationItems);
  }

  private getTopLevelRoute = (activatedRoute:ActivatedRoute): ActivatedRoute =>  {
    if (activatedRoute === activatedRoute.root && activatedRoute.firstChild) activatedRoute = activatedRoute.firstChild;
    return activatedRoute;
  };

  private emitActiveRouteSubNavigationItems  = (activatedRoute:ActivatedRoute): void => {
    const routeChildren = activatedRoute.firstChild?.routeConfig?.children  as CustomRoute[] || [];
    this.subNavigationItems$.next(routeChildren.filter(route => {
      return route.navigation
        ? (route.permissions ? this.permissionService.hasOnePermission(route.permissions) : true)
        : false;
    }).sort((a,b) => a.navigation!.order - b.navigation!.order));
  };

  getNavItems(): CustomRoute[]{
    const routes = this.router.config.find(r => r.path === 'portal')?.children as CustomRoute[];
    return routes.filter(route => {
      return route.navigation
        ? (route.permissions ? this.permissionService.hasOnePermission(route.permissions) : true)
        : false;
    }).sort((a,b) => a.navigation!.order - b.navigation!.order);
  }
}