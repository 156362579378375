import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AcmMediaProject } from 'src/app/core/clients/generated/client';
import { InvestmentDetailsModalComponent } from '../../modals/investment-details-modal/investment-details-modal.component';
import { InvestmentStrategyModalData, InvestmentStrategyModel } from '../../models/investments.models';

@Component({
  selector: 'app-investment-model',
  templateUrl: './investment-model.component.html',
})

export class InvestmentModelComponent {
  constructor(
    private dialog: MatDialog,
  ) { }
  @Input() model!: InvestmentStrategyModel;
  @Input() wistiaProject?: AcmMediaProject;

  openInvestmentDetailsModal(): void {
    if (this.model) {
      this.dialog.open<InvestmentDetailsModalComponent, InvestmentStrategyModalData>(InvestmentDetailsModalComponent, {
        maxHeight: '90rem',
        data: {
          Name: this.model.Name,
          LogoUrls: [ this.model.LogoUrl ],
          RiskTolerance: this.model.RiskTolerance,
          MoneyManager: this.model.MoneyManager,
          ManagerFee: this.model.ManagerFee,
          ModelMinimum: this.model.ModelMinimum,
          IsBetaShield: this.model.Name.toLowerCase().includes('betashield'),
          Description: this.model.Description,
          InvestmentLinks: this.model.InvestmentLinks,
          Assets: this.wistiaProject?.content.find(filter => filter.sectionId === this.model.Id)?.assets || [],
          Error: this.wistiaProject ? false : true
        }
      });
    }
  }
}
