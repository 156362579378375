import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { DevelopmentCenterPageComponent } from './pages/development-center-page/development-center-page.component';
import { VirtualLearningDetailsPageComponent } from './pages/virtual-learning-details-page/virtual-learning-details-page.component';
import { FinishStrongDetailsPageComponent } from './pages/finish-strong-details-page/finish-strong-details-page.component';
import { NextLevelPageComponent } from './pages/next-level-page/next-level-page.component';
import { DevelopmentCenterWistiaMediaComponent } from './components/development-center-wistia-media/development-center-wistia-media.component';
import { DevelopmentCenterWistiaSectionComponent } from './components/development-center-wistia-section/development-center-wistia-section.component';
import { AlphastartPageComponent } from './pages/alphastart-page/alphastart-page.component';
import { CoffeeWithCompliancePageComponent } from './pages/coffee-with-compliance-page/coffee-with-compliance-page.component';
import { SalesSummit2024PageComponent } from './pages/sales-summit-2024-page/sales-summit-2024-page.component';

@NgModule({
  declarations: [
    DevelopmentCenterPageComponent,
    VirtualLearningDetailsPageComponent,
    FinishStrongDetailsPageComponent,
    NextLevelPageComponent,
    DevelopmentCenterWistiaMediaComponent,
    DevelopmentCenterWistiaSectionComponent,
    AlphastartPageComponent,
    CoffeeWithCompliancePageComponent,
    SalesSummit2024PageComponent,
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class DevelopmentCenterModule { }
