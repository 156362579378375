<section class="flex w-full h-full p-30">
  <div class="flex flex-col w-full mx-auto max-w-1440 gap-y-30">
    <a routerLink="../overview" class="flex items-center p-5 mr-auto text-sm font-bold uppercase gap-x-5">
      <mat-icon svgIcon="arrow-left" class="mi-18"></mat-icon>
      <span>Back</span>
    </a>
    <div class="flex justify-center">
      <div>  <img class="block w-auto mr-auto h-128" src="assets/images/development-center/coffee-with-compliance-header.png"
        alt="Coffee with Compliance Resources"></div>
    </div>
    <div class="flex-col gap-x-30">
      <h2 class="flex text-xl font-bold justify-center">Coffee with Compliance</h2>
      <p class="flex text-lg justify-center">
        Keep your office sharp and informed on important and ever-changing focuses in the regulatory landscape in
        timely and regular way.
      </p>
    </div>
   

    <section class="flex flex-col gap-y-30">
      <section class="card">
        <app-development-center-wistia-section class="flex flex-col" [model]="wistiaSection"
          [wistiaProject]="wistiaProject"></app-development-center-wistia-section>
      </section>
    </section>
  </div>
</section>