import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountsPageComponent } from './pages/accounts-page/accounts-page.component';
import { AccountsListComponent } from './components/accounts/accounts-list.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from 'src/app/shared/shared.module';
import { PaperAppModalComponent } from './modals/paper-app-modal/paper-app-modal.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AccountsPageComponent,
    AccountsListComponent,
    PaperAppModalComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    SharedModule
  ]
})
export class AccountsModule { }
