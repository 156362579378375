import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fadeIn } from 'src/app/shared/constants/animations';

@Component({
  animations: [fadeIn],
  selector: 'app-create-new-password-page',
  templateUrl: './create-new-password-page.component.html'
})
export class CreateNewPasswordPageComponent {

  passwordStepCompleted = false;
  mfaSuccess = false;
  failure = this.activatedRoute.snapshot.queryParamMap.get('failure');
  constructor(private router:Router, private activatedRoute:ActivatedRoute) { }

  handlePasswordFormCompleted(): void {
    this.passwordStepCompleted = true;
  }

  handleMfaResponse(isSuccess: boolean): void {
    this.mfaSuccess = isSuccess;
  }

}
