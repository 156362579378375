import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const notViewingCustomHoldingsValidator = (currentStep:number): ValidatorFn => {
  return (form: AbstractControl): ValidationErrors | null => {
    if (!form.value || currentStep !== 3) {
      return null;
    } else {
      return { oncustomHoldingsView: true };
    }
  };
};

export const oncustomHoldingsView = 'oncustomHoldingsView';