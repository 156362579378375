import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { catchError, forkJoin } from 'rxjs';
import { AgentModel, AgentsClient, FileParameter, PlanningDeskModelBase, PlanningDeskRequestsClient } from 'src/app/core/clients/generated/client';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { fadeIn } from 'src/app/shared/constants/animations';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { ConfirmationModalData } from 'src/app/shared/modals/confirmation-modal/modal-data.models';
import { AccountTypeList, AnalysisReportTypeList, PlanningDeskRequestType, RecommendationType } from './constants/planning-desk-request.constant';
import { RequestExitConfirmationData } from '../shared/constants/workflow-navigation.constants';
import { AccountUpdateStepEnum, RequestTypeEnum } from '../shared/enums/account-update.enum';
import { AssetBreakdownFormArray, AssetEntryFormGroup, PlanningDeskAssetDetailsFormGroup, PlanningDeskGeneralInfoFormGroup, PlanningDeskRequestFormGroup } from './models/planning-desk.model';
import { AccountUpdateStepModel, AccountWorkflowNavModel } from '../shared/models/workflow.models';
import { RecommendationTypeEnum } from './enums/recommendation-type.enum';
import { AnalysisReportTypeEnum } from './enums/analysis-report-type.enum';

@Component({
  selector: 'app-planning-desk-request',
  templateUrl: './planning-desk-request.component.html',
  animations: [ fadeIn ]
})
export class PlanningDeskRequestComponent implements OnInit, AfterViewInit {
  @Input() workflow?: AccountWorkflowNavModel;

  constructor (
    private planningDeskRequestDialog: MatDialogRef<PlanningDeskRequestComponent>,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private agentClient: AgentsClient,
    private planningDeskRequestsClient: PlanningDeskRequestsClient,
    private snackbarService: SnackbarService,
  ) { }
  accountTypeList = AccountTypeList;
  advisorList?: AgentModel[];
  analysisReportTypeList = AnalysisReportTypeList;
  planningDeskRequestType = PlanningDeskRequestType;
  accountUpdateStepEnum = AccountUpdateStepEnum;
  requestTypeEnum = RequestTypeEnum;
  currentStep = this.accountUpdateStepEnum.step1;
  isLoading = false;
  isSubmitting = false;
  isSubmitted = false;
  isComplete = false;
  submitCount = 0;

  /** Step 1 sub form group */
  generalInfoFormGroup: PlanningDeskGeneralInfoFormGroup = this.fb.group({
    agent: [ null, [ Validators.required ] ],
    client: [ null, [ Validators.required ] ],
    nextAppointmentDate: [ new Date(), [ Validators.required ] ],
    requestType: [ PlanningDeskRequestType[ 0 ], [ Validators.required ] ],
    recommendationType: [ { value: RecommendationType[ 0 ], disabled: true }, [ Validators.required ] ],
    userRecommendation: [ { value: null, disabled: true }, [ Validators.required ] ],
    notes: null
  }) as PlanningDeskGeneralInfoFormGroup;

  /** Step 2 sub form group */
  assetDetailsFormGroup = this.fb.group({
    assetBreakdown: this.fb.array([
      this.fb.group({
        accountType: [ null, [ Validators.required ] ],
        currentValue: [ null, [ Validators.required ] ],
        clientFee: [ null, [ Validators.required ] ]
      }) as AssetEntryFormGroup
    ]) as AssetBreakdownFormArray,
    files: [ null, [ Validators.required ] ],
    analysisReportType: [ null, [ Validators.required ] ],
    analysisReportTypeOther: [ null, [ Validators.required ] ]
  }) as PlanningDeskAssetDetailsFormGroup;

  /** Parent form group */
  planningDeskRequestFormGroup = this.fb.group({
    generalInfo: this.generalInfoFormGroup,
    assetDetails: this.assetDetailsFormGroup
  }) as PlanningDeskRequestFormGroup;

  pages: AccountUpdateStepModel[] = [
    {
      formGroup: this.generalInfoFormGroup,
      value: this.accountUpdateStepEnum.step1,
    },
    {
      formGroup: this.assetDetailsFormGroup,
      value: this.accountUpdateStepEnum.step2,
    },
    {
      formGroup: this.planningDeskRequestFormGroup,
      value: this.accountUpdateStepEnum.step3,
    },
  ];

  ngOnInit(): void {
    this.getData();
  }

  ngAfterViewInit(): void {
    this.updateDialog();
  }

  private createPostModel(): PlanningDeskModelBase {
    const postModel: PlanningDeskModelBase = {
      agentID: this.planningDeskRequestFormGroup.value.generalInfo.agent.agentID,
      meetingOn: this.planningDeskRequestFormGroup.value.generalInfo.nextAppointmentDate,
      planningDeskCategoryID: this.planningDeskRequestFormGroup.value.generalInfo.requestType.value,
      planningDeskDetails: [],
    };

    // Weird concatenation of the report types since they'll need to be appended as part of the notes on CRM
    const requestedReports = this.planningDeskRequestFormGroup.value.assetDetails.analysisReportType.filter(x => x.value !== AnalysisReportTypeEnum.other).map(x => x.name);
    const reportTypeOther = this.planningDeskRequestFormGroup.value.assetDetails.analysisReportTypeOther ? this.planningDeskRequestFormGroup.value.assetDetails.analysisReportTypeOther : null;
    let recommendationType: string | null = null;
    let notes = '';
    if (this.planningDeskRequestFormGroup.value.generalInfo.recommendationType) {
      this.planningDeskRequestFormGroup.value.generalInfo?.recommendationType.value === RecommendationTypeEnum.user ? 'User\'s Recommendation' : 'Alphastar\'s recommendation';
    } else {
      recommendationType = null;
    }
    if (reportTypeOther) requestedReports.push(reportTypeOther);

    this.planningDeskRequestFormGroup.value.assetDetails.assetBreakdown.forEach(entry => {
      if (recommendationType) {
        notes =
          `Requested Reports: ${requestedReports.join(', ')}. Recommendation: ${recommendationType}.\n\n${this.planningDeskRequestFormGroup.value.generalInfo.notes}`;
      } else {
        notes =
          `Requested Reports: ${requestedReports.join(', ')}.\n\n${this.planningDeskRequestFormGroup.value.generalInfo.notes}`;
      }
      postModel.planningDeskDetails.push({
        proposalTypeID: 3,
        accountOwner: this.planningDeskRequestFormGroup.value.generalInfo.client,
        investmentAccountTypeID: entry.accountType.value,
        currentAccountValue: entry.currentValue,
        proposedFee: entry.clientFee,
        notes: notes,
      });
    });

    return postModel;
  }

  private createFileParameter(files: File[]): FileParameter[] {
    return files.map(file => {
      return {
        fileName: file.name,
        data: file,
      };
    });
  }

  private postPlanningDeskRequest(files: FileParameter[], planningDeskModelBase: PlanningDeskModelBase): void {
    this.isSubmitting = true;
    this.planningDeskRequestsClient.createPlanningDeskRequest(files, planningDeskModelBase).subscribe({
      error: () => {
        this.submitCount++;
        // for work around on sometimes the first request failing submitting to the crm.
        if (this.submitCount < 2){
          console.log('received error, retrying in 2 seconds');
          setTimeout(() =>{
            this.onSubmit();
          }, 2000);
        } else {
          this.isSubmitting = false;
          this.isSubmitted = true;
          this.isComplete = false;
          this.submitCount = 0;
        }
      },
      complete: () => {
        this.isSubmitting = false;
        this.isSubmitted = true;
        this.isComplete = true;
      }
    });
  }

  private updateDialog(): void {
    this.planningDeskRequestDialog.updateSize('60rem', '82rem');
  }

  private getData(): void {
    this.isLoading = true;
    const advisorListApi = this.agentClient.getParentAgents(false).pipe(catchError(error => {
      throw error;
    }));
    const loggedInAdvisorApi = this.agentClient.getAgentSelf().pipe(catchError(error => {
      throw error;
    }));
    const promise = { advisorList: advisorListApi, loggedInAdvisor: loggedInAdvisorApi };
    forkJoin(promise)
      .subscribe({
        next: (res) => {
          res.advisorList.unshift(res.loggedInAdvisor);
          this.advisorList = res.advisorList;
          this.planningDeskRequestFormGroup.controls.generalInfo.controls.agent.patchValue(res.loggedInAdvisor);
        },
        error: () => {
          this.snackbarService.openSnackbar('Error retrieving information. Please try again later.', ToastClassEnum.warning);
        },
      }).add(() => {
        this.isLoading = false;
      });
  }

  previousStep(): void {
    if (this.currentStep > this.accountUpdateStepEnum.step1) {
      this.currentStep--;
    }
  }

  nextStep(formGroup: UntypedFormGroup): void {
    if (formGroup.invalid) {
      formGroup.markAllAsTouched();
    } else {
      if ((this.currentStep < this.accountUpdateStepEnum.step4)) {
        this.currentStep++;
      }
    }
  }

  onSubmit(): void {
    if (this.planningDeskRequestFormGroup.valid) {
      const postModel = this.createPostModel();
      const files = this.createFileParameter(this.planningDeskRequestFormGroup.value.assetDetails.files);
      this.postPlanningDeskRequest(files, postModel);
    }
  }

  cancelRequest(): void {
    if (this.isSubmitted && this.isComplete) {
      this.planningDeskRequestDialog.close(false);
      return;
    }

    const confirmationDialog = this.dialog.open<ConfirmationModalComponent, ConfirmationModalData>(ConfirmationModalComponent, {
      data: RequestExitConfirmationData
    });

    confirmationDialog.afterClosed().subscribe(res => {
      if (res) this.planningDeskRequestDialog.close(false);
      else return;
    });
  }
}
