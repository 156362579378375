import { TradeInstructionOptionModel } from '../models/workflow.models';
import { Share, Dollar } from './allocation-amount.constant';
import { Buy, Sell } from './trade-action.constants';

export const CustomHoldingsBuyActions: TradeInstructionOptionModel[] = [
  {
    tradeType: Buy,
    allocationAmountType: Share,
    name: 'Shares',
    tradeInstructionOptionID: 3,
    isStock: true,
    isSellAll: false,
  },
  {
    tradeType: Buy,
    allocationAmountType: Dollar,
    name: 'Mutual Funds (Dollars)',
    tradeInstructionOptionID: 5,
    isStock: false,
    isSellAll: false,
  },
  {
    tradeType: Buy,
    allocationAmountType: Share,
    name: 'Mutual Funds (Shares)',
    tradeInstructionOptionID: 8,
    isStock: false,
    isSellAll: false,
  }
];

export const CustomHoldingsSellActions: TradeInstructionOptionModel[] = [
  {
    tradeType: Sell,
    allocationAmountType: Share,
    name: 'Shares',
    tradeInstructionOptionID: 1,
    isStock: true,
    isSellAll: false,
  },
  {
    tradeType: Sell,
    allocationAmountType: Share,
    name: 'All Shares',
    tradeInstructionOptionID: 2,
    isStock: true,
    isSellAll: true
  },
  {
    tradeType: Sell,
    allocationAmountType: Dollar,
    name: 'Mutual Funds (Dollars)',
    tradeInstructionOptionID: 4,
    isStock: false,
    isSellAll: false,
  },
  {
    tradeType: Sell,
    allocationAmountType: Share,
    name: 'All Mutual Funds',
    tradeInstructionOptionID: 6,
    isStock: false,
    isSellAll: true,
  },
  {
    tradeType: Sell,
    name: 'Mutual Funds (Shares)',
    allocationAmountType: Share,
    tradeInstructionOptionID: 7,
    isStock: false,
    isSellAll: false,
  },
];