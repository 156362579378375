import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { PlayersApiService } from 'src/app/core/http/players-api.service';
import { TeamsApiService } from 'src/app/core/http/teams-api.service';
import { Player } from '../../models/player';
import { Team } from '../../models/team';

@Component({
  selector: 'app-playerlist',
  templateUrl: './playerlist.component.html',
})
export class PlayerListComponent implements OnInit, OnChanges {
// The ! is a way of telling typescript that despite the fact we are not assigning this property a value. We know it will not be undefined.
  players:Player[] = [];
  team!:Team;
  isLoading = true;
  @Input() teamId!: number;


  constructor(private playersApiService:PlayersApiService, private teamsApiService:TeamsApiService) { }

  ngOnInit(): void {
    this.getPlayers();
  }

  getPlayers():void{
    this.isLoading = true;
    forkJoin({
      players: this.playersApiService.getPlayersByTeamId(this.teamId),
      team: this.teamsApiService.getTeam(this.teamId)
    })
      .subscribe({
        next: res => {
          this.players = res.players,
          this.team = res.team;
        },
        error: e => console.log(e),
        complete: () => console.log('IMPORTANT: this is only hit if the api call is a success')
      })
      .add(() => {
        console.log('IMPORTANT: this callback is ran regardless of whether the API call is a success or not');
        this.isLoading = false;
      });
  }

  ngOnChanges():void{
    this.getPlayers();
  }

}
