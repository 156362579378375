import { Component } from '@angular/core';

@Component({
  selector: 'app-first-mfa-verification-page',
  templateUrl: './first-mfa-verification-page.component.html'
})
export class FirstMfaVerificationPageComponent {

  constructor() { }

}
