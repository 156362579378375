import { CurrencyPipe } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { DeliveryMethods, AllocationFrequencies, WireTransferRecipient } from 'src/app/portal/features/account-request-workflows/shared/constants/allocation-method-frequency.constant';
import { AccountUpdateStepEnum, RequestTypeEnum } from 'src/app/portal/features/account-request-workflows/shared/enums/account-update.enum';
import { ReviewModel } from 'src/app/portal/features/account-request-workflows/shared/models/review.model';
import { allocationAmountEqualValidator } from 'src/app/portal/features/account-request-workflows/shared/validators/allocation-amount-equal.validator';
import { allocationMinimumEntriesValidator } from 'src/app/portal/features/account-request-workflows/shared/validators/allocation-minimum-entries.validator';
import { notViewingCustomHoldingsValidator } from 'src/app/portal/features/account-request-workflows/shared/validators/not-viewing-custom-holdings.validator';
import { DistributionRequestsClient, FileParameter } from '../../../../../core/clients/generated/client';
import { InvestmentRequestFormService } from '../../services/investment-request-form.service';
import { DeliveryMethodEnum } from '../../shared/enums/delivery-methods.enum';
import { TradeFrequencyEnum } from '../../shared/enums/trade-frequency.enum';
import { AllocationCustomHoldingSleeveFormArray, AllocationFormGroup } from '../../shared/models/allocation-form.models';
import { DeliveryOptionsFormGroup } from '../../shared/models/delivery-options-form.models';
import { AccountUpdateStepModel } from '../../shared/models/workflow.models';
import { customAllocationMinimumValidator } from '../../shared/validators/custom-allocation-minimum.validator';
import { DistributionAmountInputFormGroup, DistributionFormGroup } from '../models/distribution-form.model';

@Injectable()
export class DistributionFormService extends InvestmentRequestFormService implements OnDestroy {
  /**
   * Step 1 sub form group
   * We set these values to fire value change event on input blur to reduce performance tax while user is editing the amount values
   * */

  private readonly amountInputFormGroup = this.fb.group({
    type: this.fb.control('Gross'),
    typeAmount: this.fb.control(null, {
      validators: Validators.required
    }),
    netAmount: this.fb.control(null),
    grossAmount: this.fb.control(null),
    stateTaxAmount: this.fb.control(null),
    federalTaxAmount: this.fb.control(null),
  }) as DistributionAmountInputFormGroup;

  /** Step 2 sub form group */
  private readonly allocationConfigurationFormGroup = this.fb.group({
    sleeves: this.fb.array([], []),
    notes: this.fb.control(''),
    useCurrentSetup: null,
    sellAllEvenly: false,
    showCustomHoldings: [false, [notViewingCustomHoldingsValidator(this.currentStep)]],
    customHoldings: this.fb.group({
      notes: null,
      allocation: this.fb.array([]),
      sellAllCustomHoldings: this.fb.control(null)
    }),
  }, {
    validators: [allocationMinimumEntriesValidator(), allocationAmountEqualValidator(() => this.initialAmount), customAllocationMinimumValidator(RequestTypeEnum.distribution)]
  }) as AllocationFormGroup;

  /** Step 3 sub form group */
  private readonly deliveryOptionsFormGroup = this.fb.group({
    deliveryMethod: [DeliveryMethodEnum.ach, [Validators.required]],
    tradeFrequency: [TradeFrequencyEnum.oneTime, [Validators.required]],
    initiationDate: [new Date(), [Validators.required]],
    files: null,
    isOvernightCheckDelivery: false,
    journalCashRecipientAccountNumber: ['', [Validators.required]],
    wireDeliveryOptionId: ['', [Validators.required]],
  }) as DeliveryOptionsFormGroup;

  /** Parent form group */
  readonly form = this.fb.group({
    amount: this.amountInputFormGroup,
    allocationConfiguration: this.allocationConfigurationFormGroup,
    deliveryOptions: this.deliveryOptionsFormGroup
  }) as DistributionFormGroup;

  readonly pages: AccountUpdateStepModel[] = [
    {
      formGroup: this.amountInputFormGroup,
      value: AccountUpdateStepEnum.step1,
    },
    {
      formGroup: this.allocationConfigurationFormGroup,
      value: AccountUpdateStepEnum.step2,
    },
    {
      formGroup: this.deliveryOptionsFormGroup,
      value: AccountUpdateStepEnum.step3,
    },
    {
      formGroup: this.form,
      value: AccountUpdateStepEnum.step4,
    }
  ];
  readonly distributionAmountChange?: Subscription;
  readonly distributionNetGrossChange?: Subscription;
  readonly sellAllEvenlyChange?: Subscription;
  readonly sellAllCustomHoldingsSubscription?: Subscription;
  readonly allocationHeader = '';
  readonly allocationFooter = 'Distribution Amount';
  get allocationAmountNotEqualError(): string {
    return `Your allocations do not add up to the distribution amount of ${this.currencyPipe.transform(this.initialAmount) ?? 0}`;
  }

  constructor(fb: UntypedFormBuilder, private distributionsRequestClient: DistributionRequestsClient, private currencyPipe: CurrencyPipe) {
    super(fb);
    this.sellAllEvenlyChange = this.form.controls.allocationConfiguration.controls.sellAllEvenly.valueChanges
      .pipe(
        distinctUntilChanged())
      .subscribe(value => {
        this.sellAllEvenlyChanged(value as boolean);
      });

    this.distributionAmountChange = this.form?.controls.amount.controls.grossAmount.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(250))
      .subscribe(() => {
        this.initSleeveInput();
      });

    this.distributionNetGrossChange = this.form?.controls.amount.controls.type.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(250))
      .subscribe(() => {
        this.initSleeveInput();
      });

    this.sellAllCustomHoldingsSubscription = this.customHoldings?.controls.sellAllCustomHoldings.valueChanges
      .pipe(
        distinctUntilChanged())
      .subscribe(sellAllEvenly => {
        if (sellAllEvenly) {
          this.customHoldings?.controls.allocation.clear();
        }
      });
  }

  get initialAmount(): number {
    return this.amountInputFormGroup.value.grossAmount;
  }

  get customHoldingAllocations(): AllocationCustomHoldingSleeveFormArray {
    return this.form.controls.allocationConfiguration.controls.customHoldings.controls.allocation;
  }

  initSleeveInput(): void {
    if (this.form?.controls?.allocationConfiguration?.value?.sellAllEvenly) {
      this.sellAllEvenlyChanged(this.form?.controls?.allocationConfiguration?.value?.sellAllEvenly as boolean);
    } else {
      //clear out sleeve allocation amounts since amount available to allocate has changed.
      this.form?.controls?.allocationConfiguration.controls.sleeves.controls.forEach(sleeve => {
        sleeve.controls.amount.setValue(null);
      });
    }
  }

  sellAllEvenlyChanged(sellAllEvenly: boolean): void {
    if (sellAllEvenly) {
      this.customHoldingAllocations?.clear();
      const existingCustomSleeveIndex = this.sleevesFormArray.value.findIndex(sleeve => sleeve.isCustomSleeve);
      if (existingCustomSleeveIndex >= 0) this.sleevesFormArray.removeAt(existingCustomSleeveIndex);
      this.setSleeveAmountsEvenly(this.sleevesFormArray, this.initialAmount);
      this.sleevesFormArray.disable();
    } else {
      const existingCustomSleeve = this.sleevesFormArray.value.find(sleeve => sleeve.isCustomSleeve);
      if (!existingCustomSleeve && this.customSleeve) this.sleevesFormArray.push(this.customSleeve);
      this.setSleeveAmountsToNull(this.sleevesFormArray);
      this.sleevesFormArray.enable();
    }
  }

  postRequest(investmentAccountID: number): Observable<number> {
    const model = {
      investmentAccountID: investmentAccountID,
      isSolicited: this.isSolicited,
      requestDetail: {
        frequencyID: this.deliveryOptionsFormGroup.value.tradeFrequency,
        grossAmount: this.amountInputFormGroup.value.grossAmount,
        startDate: this.deliveryOptionsFormGroup.value.initiationDate,
        deliveryMethodID: this.deliveryOptionsFormGroup.value.deliveryMethod,
        notes: this.allocationConfigurationFormGroup.value.notes,
        isCustomAllocation: !this.allocationConfigurationFormGroup.value.sellAllEvenly,
        isOvernightCheckDelivery: this.deliveryOptionsFormGroup.value.isOvernightCheckDelivery ?? undefined,
        wireDeliveryOptionID: this.deliveryOptionsFormGroup.value.wireDeliveryOptionId ?? undefined,
        fromAccountNumber: this.deliveryOptionsFormGroup.value.journalCashRecipientAccountNumber ?? undefined,
        federalTax: this.amountInputFormGroup.value.federalTaxAmount ?? 0,
        stateTax: this.amountInputFormGroup.value.stateTaxAmount ?? 0,
        requestSleeves: this.allocationConfigurationFormGroup.controls.sleeves.value.filter(x => x.amount).map((x) => {
          return {
            investmentAccountModelID: x.id,
            accountNumber: x.accountNumber,
            amount: x.amount,
            accountTotalFee: undefined,
            isNewSleeve: undefined,
          };
        }),
      },
      tradeInstruction: (this.allocationConfigurationFormGroup.value.customHoldings.allocation.length || this.allocationConfigurationFormGroup.value.customHoldings.notes?.length) ? {
        tradeInstructionID: this.allocationConfigurationFormGroup.value.customHoldings.sellAllCustomHoldings ? 1 : 3,
        notes: this.allocationConfigurationFormGroup.value.customHoldings.notes,
        tradeInstructionOptions: this.allocationConfigurationFormGroup.value.customHoldings.allocation.map((x) => {
          return {
            tradeInstructionOptionID: x.action.tradeInstructionOptionID,
            symbol: x.stockSymbol,
            amount: x.amount
          };
        })
      } : undefined
    };
    const deliveryOptionsFormGroup = this.form.controls.deliveryOptions;
    const files: FileParameter[] = deliveryOptionsFormGroup.value.files?.map(file => {
      return { data: file, fileName: file.name };
    }) || [];

    return this.distributionsRequestClient.createDistributionRequest(files, model);
  }

  getReviewModel(): ReviewModel {
    const distributionForm = this.form as DistributionFormGroup;
    const customHoldingsAmount = distributionForm.value.allocationConfiguration.sleeves?.find(x => x.isCustomSleeve)?.amount;
    const deliveryOptionsFormGroup = this.form.controls.deliveryOptions as DeliveryOptionsFormGroup;

    return {
      amount: distributionForm.value.amount.grossAmount,
      taxWithholding: {
        federalPercent: distributionForm.value.amount.federalTaxAmount,
        statePercent: distributionForm.value.amount.stateTaxAmount,
        receiveAmount: distributionForm.value.amount.netAmount,
      },
      sleeves: distributionForm.controls.allocationConfiguration.controls.sleeves.value.filter(x => !x.isCustomSleeve && x.amount).map((x) => {
        return {
          name: x.model,
          amount: x.amount
        };
      }),
      customHoldings: customHoldingsAmount ? {
        amount: customHoldingsAmount,
        holdings: distributionForm.value.allocationConfiguration.customHoldings.allocation.map((x) => {
          return {
            name: x.action.isStock ? x.stockSymbol : x.action.name,
            type: x.type,
            amount: (!x.action.isStock && x.action.allocationAmountType === 'Dollar') ? x.amount : undefined,
            shareCount: (x.action.isStock || x.action.allocationAmountType === 'Share') ? x.amount : undefined,
          };
        }),
        notes:  distributionForm.value.allocationConfiguration.customHoldings.notes,
      } : undefined,
      delivery: {
        method: DeliveryMethods.find(x => x.value === distributionForm.value.deliveryOptions.deliveryMethod)?.name as string,
        methodEnum: distributionForm.value.deliveryOptions.deliveryMethod as DeliveryMethodEnum,
        frequency: AllocationFrequencies.find(x => x.value === distributionForm.value.deliveryOptions.tradeFrequency)?.name as string,
        startDate: distributionForm.value.deliveryOptions.initiationDate,
        journalAccountNumber: distributionForm.value.deliveryOptions.journalCashRecipientAccountNumber,
        wireTransferTo: WireTransferRecipient.find(x => x.value === distributionForm.value.deliveryOptions.wireDeliveryOptionId)?.name as string
      },
      notes: distributionForm.value.allocationConfiguration.notes,
      files: deliveryOptionsFormGroup.value.files
    };
  }

  // Since this is a scoped service it will be destroyed when the component it is associated with is destroyed
  ngOnDestroy(): void {
    this.sellAllCustomHoldingsSubscription?.unsubscribe();
    this.sellAllEvenlyChange?.unsubscribe();
    this.distributionAmountChange?.unsubscribe();
    this.distributionNetGrossChange?.unsubscribe();
  }
}
