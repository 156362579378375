import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DeliveryMethods, AllocationFrequencies, WireTransferRecipient } from '../../constants/allocation-method-frequency.constant';
import { RequestTypeEnum } from '../../enums/account-update.enum';
import { WireTransferRecipientEnum } from '../../enums/wire-transfer-recipient.enum';
import { DeliveryMethodEnum } from '../../enums/delivery-methods.enum';
import { TradeFrequencyEnum } from '../../enums/trade-frequency.enum';
import { DeliveryOptionsFormGroup } from '../../models/delivery-options-form.models';


@Component({
  selector: 'app-delivery-methods',
  templateUrl: './delivery-methods.component.html',
})
export class DeliveryMethodsComponent implements OnInit {
  /** The type of request (contribution, distribution, or reallocation). */
  @Input() requestType?: RequestTypeEnum;
  @Input() parentForm?: DeliveryOptionsFormGroup;
  requestTypeEnum = RequestTypeEnum;
  /** Controls whether the delivery method input field is disabled in the UI. Default is true (i.e., that input field is disabled). */
  methodSelectionDisabled = true;
  /** The text that displays in the UI for a given request type. */
  requestTypeLabel = '';
  deliveryMethodList = DeliveryMethods;
  tradeFrequencyList = AllocationFrequencies;
  transferRecipientList = WireTransferRecipient;
  transferRecipientEnum = WireTransferRecipientEnum;
  deliveryMethodEnum: typeof DeliveryMethodEnum = DeliveryMethodEnum;
  tradeFrequencyEnum: typeof TradeFrequencyEnum = TradeFrequencyEnum;
  minDate: Date = new Date();

  ngOnInit(): void {
    this.updateContent();
    this.updateDeliveryMethod(this.parentForm?.value.deliveryMethod as DeliveryMethodEnum);
    this.updateDeliveryFrequency(this.parentForm?.value.tradeFrequency as TradeFrequencyEnum);
    if ((this.parentForm?.controls.initiationDate.value as Date).toLocaleDateString() === this.minDate.toLocaleDateString())
      this.setMinDateDistribution();
  }

  get deliveryMethodControl(): UntypedFormControl | undefined {
    if (this.parentForm) return this.parentForm?.controls.deliveryMethod;
    return;
  }

  // Is set to be today + 1 day
  setMinDateDistribution(): void {
    if (this.requestType === this.requestTypeEnum.distribution) {
      this.minDate.setDate(this.minDate.getDate() + 1);
    }
    // default date picker to min date
    this.parentForm?.controls.initiationDate.setValue(this.minDate);
  }

  /** Updates the configuration based on the type of request passed in by the parent component. */
  updateContent(): void {
    if (this.requestType === this.requestTypeEnum.contribution) {
      this.deliveryMethodControl?.disable();
      this.requestTypeLabel = 'contribution';
    } else if (this.requestType === this.requestTypeEnum.distribution) {
      this.deliveryMethodControl?.enable();
      this.requestTypeLabel = 'distribution';
    }
  }

  updateDeliveryMethod(deliveryMethodId: DeliveryMethodEnum): void {
    if (!this.parentForm) return;
    switch (deliveryMethodId) {
      case this.deliveryMethodEnum.check:
        this.parentForm.controls.isOvernightCheckDelivery.enable();
        this.parentForm.controls.journalCashRecipientAccountNumber.disable();
        this.parentForm.controls.wireDeliveryOptionId.disable();
        break;
      case this.deliveryMethodEnum.wireTransfer:
        this.parentForm.controls.isOvernightCheckDelivery.disable();
        this.parentForm.controls.journalCashRecipientAccountNumber.disable();
        this.parentForm.controls.wireDeliveryOptionId.enable();
        break;
      case this.deliveryMethodEnum.journalCash:
        this.parentForm.controls.isOvernightCheckDelivery.disable();
        this.parentForm.controls.journalCashRecipientAccountNumber.enable();
        this.parentForm.controls.wireDeliveryOptionId.disable();
        break;
      default:
        this.disableDeliverySubControls();
        break;
    }
  }

  updateDeliveryFrequency(tradeFrequencyId: TradeFrequencyEnum): void {
    if (!this.parentForm) return;
    if (tradeFrequencyId === TradeFrequencyEnum.oneTime) {
      this.parentForm.controls.initiationDate.disable();
    } else {
      this.parentForm.controls.initiationDate.enable();
    }
  }

  disableDeliverySubControls(): void {
    if (!this.parentForm) return;
    this.parentForm.controls.isOvernightCheckDelivery.disable();
    this.parentForm.controls.journalCashRecipientAccountNumber.disable();
    this.parentForm.controls.wireDeliveryOptionId.disable();
  }

  uploadFiles(files: File[]): void {
    if (!this.parentForm) return;
    if (files) this.parentForm.controls.files.patchValue(files);
    else this.parentForm.controls.files.patchValue(null);
  }
}
