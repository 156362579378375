import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { InvestmentRequestFormService } from '../../../services/investment-request-form.service';
import { RequestTypeEnum } from '../../enums/account-update.enum';
import { AllocationFormGroup, AllocationCustomHoldingsFormGroup, AllocationCustomHoldingSleeveFormArray } from '../../models/allocation-form.models';
@Component({
  selector: 'app-allocation-step',
  templateUrl: './allocation-step.component.html'
})
export class AllocationStepComponent implements OnInit {
  @Input() initialAmount?: number;
  @Input() parentForm?: AllocationFormGroup;
  @Input() requestType?: RequestTypeEnum;
  @Input() investmentRequestFormService?: InvestmentRequestFormService;
  requestTypeEnum = RequestTypeEnum;
  customHoldings?: AllocationCustomHoldingsFormGroup;
  customHoldingAllocations?: AllocationCustomHoldingSleeveFormArray;

  ngOnInit(): void {
    this.customHoldings = this.parentForm?.controls.customHoldings;
    this.customHoldingAllocations = this.customHoldings?.controls.allocation;
  }

  get customHoldingsAmount(): number|undefined {
    return this.parentForm?.controls.sleeves.value.find(sleeve => sleeve.isCustomSleeve)?.amount;
  }

  get showCustomHoldings(): boolean {
    return this.parentForm?.value.showCustomHoldings || false;
  }

  get showCustomHoldingsFormControl(): UntypedFormControl|undefined {
    return this.parentForm?.controls.showCustomHoldings;
  }
}