import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { FirstMfaSetupClient } from 'src/app/core/clients/generated/client';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';

@Component({
  selector: 'app-first-mfa-setup-form',
  templateUrl: './first-mfa-setup-form.component.html'
})
export class FirstMfaSetupFormComponent {

  firstMfaSetupForm = this.formBuilder.group({
    phoneNumber: [
      '', [
      ]
    ],
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private firstMfaClient: FirstMfaSetupClient,
    private snackbar: SnackbarService,
  ) { }

  onSubmit(): void {
    this.firstMfaClient.setupMfaDevice({
      phoneNumber: this.firstMfaSetupForm.value.phoneNumber,
    }).subscribe({
      next: () => {
        this.router.navigateByUrl('/first-mfa-verification');
      },
      error: () => {
        // not expected to happen - only on random network errors or if user is trying to bypass the first-mfa-setup auth rules on the api
        this.snackbar.openSnackbar('There was an error processing your request.', ToastClassEnum.warning);
      }
    });
  }

}
