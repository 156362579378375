import { Component, Input, OnInit } from '@angular/core';
import { PlayersApiService } from 'src/app/core/http/players-api.service';
import { Player } from '../../models/player';

@Component({
  selector: 'app-playerdetail',
  templateUrl: './playerdetail.component.html',
})
export class PlayerdetailComponent implements OnInit {
  // The ! is a way of telling typescript that despite the fact we are not assigning this property a value. We know it will not be undefined.
  @Input() playerId!:number;
  player!:Player;

  constructor(private playersApiService:PlayersApiService) { }

  ngOnInit(): void {
    this.playersApiService.getPlayerById(this.playerId)
      .subscribe({
        next: (player) => this.player = player,
        error: (e) => console.log(e),
        complete: () => console.log('IMPORTANT: this is only hit if the api call is a success')
      })
      .add(() => {
        console.log('IMPORTANT: this callback is ran regardless of whether the API call is a success or not');
      });
  }

}
