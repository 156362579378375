import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

/*  Whenever you change routes to go to new components all of your state is lost. We use store services which are lightweight ways to maintain state.
  We are saving the selected teamID so when you are going back to the teams page after viewing a player your most recenlty selected team is the one that loads */

//NOTE: refreshing the page will clear this store
//I'm also going to use getters/setters here just as an example of how that works

export class ExampleStoreService {
  //Default to TeamID 1
  private selectedTeamId = 1;

  get SelectedTeamId():number {
    return this.selectedTeamId;
  }

  set SelectedTeamId(Id:number) {
    this.selectedTeamId = Id;
  }
}
