/** these are ids from database */
export enum TradeFrequencyEnum {
  oneTime = 1,
  biWeekly = 2,
  monthly = 3,
  quarterly = 4,
  weekly = 5,
  annually = 6,
  biAnnually = 7,
  firstBizDayOfMonth = 8,
  lastBizDayOfMonth = 9,
  firstFridayOfMonth = 10,
  secondFridayOfMonth = 11,
  thirdFridayOfMonth = 12,
  fourthFridayOfMonth = 13,
  everyTwoWeeks = 14,
}