import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AcmMediaProject, WistiaClient } from 'src/app/core/clients/generated/client';
import { WistiaProjectsEnum } from 'src/app/shared/enums/wistia.enum';
import { getWistiaSection } from 'src/app/shared/helpers/wistiaSection.helpers';
import { DevelopmentCenterWistiaSection, DevelopmentCenterWistiaSectionEnum } from '../../models/development-center.models';

@Component({
  templateUrl: './alphastart-page.component.html',
})
export class AlphastartPageComponent implements OnInit {
  wistiaSection?: DevelopmentCenterWistiaSection;
  wistiaProject?: AcmMediaProject;
  constructor (
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private wistiaApiService: WistiaClient,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.activatedRoute.snapshot.data['title'] + ' | Alphastar Portal');
    this.wistiaApiService.getProjectAssets(WistiaProjectsEnum.DevelopmentCenter).subscribe({
      next: result => {
        this.wistiaProject = result;
        this.wistiaSection = getWistiaSection(this.wistiaProject, DevelopmentCenterWistiaSectionEnum.AlphaStartViewMore);
      }
    });
  }

}
