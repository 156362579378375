/* tslint:disable */
/* eslint-disable */
/*
disabling linting to help with autogenerated files

for some reason nswag generates this exception class with an extra message property - that fails to compile since Error already defines a message property.
current workaround is to tell nswag not to generate the exception class at all, and instead we define it properly here, and reference it in the generated clients.
that is done by:
- "extensionCode": "import { ApiException } from '../api-exception';",
- "excludedTypeNames": ["ApiExceptiosn"],
*/

export class ApiException extends Error {
  // message: string;
  status: number;
  response: string;
  headers: { [key: string]: any; };
  result: any;

  constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
    super();

    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
  }

  protected isApiException = true;

  static isApiException(obj: any): obj is ApiException {
    return obj.isApiException === true;
  }
}