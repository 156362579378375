import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionsService } from 'src/app/core/auth/permissions.service';
import { PermissionsClient } from 'src/app/core/clients/generated/client';

@Component({
  selector: 'app-mfa-verification-page',
  templateUrl: './mfa-verification-page.component.html',
})
export class MfaVerificationPageComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private router: Router,
    private permissionsApiService: PermissionsClient,
    private permissionsService: PermissionsService
  ){ }

  ngOnInit(): void {
    this.titleService.setTitle(this.activatedRoute.snapshot.data['title'] + ' | Alphastar Portal');
  }

  handleMfaResult(isSuccess:boolean): void {
    if(isSuccess) {
      this.permissionsApiService.getUserPermissions().subscribe({
        next: (permissions) =>  {
          this.permissionsService.setPermissions(permissions);
          this.router.navigateByUrl('');
        }
      });
    } else {
      this.router.navigateByUrl('/login?error=invalid');
    }

  }
}