import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-file-dropper',
  templateUrl: './file-dropper.component.html'
})
export class FileDropperComponent implements OnInit {
  // TODO: This component needs to be refactored at some point. It is currently in a working state, but could be improved in the following ways:
  // 1. Make this a reactive form. This would be consistent with the team's chosen strategy for working with forms, and would provide many other benefits.
  // 2. Accept a FormGroup as the input. In other words, the outermost element would be a <form>, and it would have a [formGroup] attribute that accepted, well, a form group.
  // 3. Simplify and reduce. This is currently emitting a strong "hack" smell.

  dragover = false;
  showUploadErrorMessage = false;
  uploadErrorMessage = '';
  files: File[] = [];
  fileExtensionMessage = '';
  @Input() value?: File[];
  @Input() fileExtensions: string[] = ['doc', 'docx', 'pdf', 'jpg', 'jpeg', 'png', 'xls', 'xlsx', 'csv'];
  @Output() fileChangeEvent = new EventEmitter<File[]>();
  @ViewChild('fileUpload') fileUpload!: ElementRef<HTMLInputElement>;

  ngOnInit(): void {
    if (this.value) this.files = this.value;
    this.fileChangeEvent.emit(this.files);
    this.fileExtensionMessage = this.fileExtensions.map(ext => ext.toUpperCase()).join(', ');
  }

  dragOverHandler(event: Event): void {
    this.dragover = true;
    event.preventDefault();
    event.stopPropagation();
  }

  /** Extracts files from a file upload event and adds them to the form post model.
   * The maximum size for a single file is 5MB or less.
   * The file type must be one of the following: pdf, jpg, jpeg, png.
   */
  uploadFiles(event: Event): void {
    this.dragover = false;
    if (event.target) {
      const fileList = (event.target as HTMLInputElement).files;
      this.addFilesToFormPostModel(fileList);
    }
  }

  private addFilesToFormPostModel(fileList: FileList | null): void {
    let filesChanged = false;
    if (fileList) {
      this.showUploadErrorMessage = false;
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);
        const addedFile = this.appendFileToFormPostModel(file);
        if (addedFile) {
          filesChanged = true;
        }
      }
      if (filesChanged) {
        this.fileChangeEvent.emit(this.files);
      }
    }
  }

  private appendFileToFormPostModel(file: File | null): boolean {
    if (file) {
      const doesNotExist = this.files.indexOf(file) === -1;
      if (doesNotExist) {
        const validationResult = this.isValidFile(file);
        if (validationResult.isSuccess) {
          this.files.push(file);
          return true;
        } else {
          this.showUploadErrorMessage = true;
          this.uploadErrorMessage = validationResult.errorType === 'size' ? 'File size must be less than 30MB.' : ' Please select a valid file type.';
        }
      }
    }
    return false;
  }

  private isValidFile(file: File): { isSuccess: boolean, errorType: 'size' | 'ext' | null } {
    const fileName: string = file.name;
    const fileSize: number = file.size;
    const idxDot = fileName.lastIndexOf('.') + 1;
    const extFile = fileName.substring(idxDot, fileName.length).toLowerCase();
    const extCheck: boolean = this.fileExtensions.includes(extFile);
    const sizeCheck: boolean = fileSize <= 30000000;

    return {
      isSuccess: extCheck && sizeCheck,
      errorType: !extCheck ? 'ext' : !sizeCheck ? 'size' : null,
    };
  }

  removeFile(fileToDelete: File): void {
    if (this.files && fileToDelete) {
      const index = this.files.indexOf(fileToDelete);
      if (index !== -1) {
        this.files.splice(index, 1);
        this.fileChangeEvent.emit(this.files);
        this.resetHtmlInputElement();
      }
    }
  }

  /** This function modifies the DOM to account for a quirk in how files are handled in HTML. */
  private resetHtmlInputElement(): void {
    this.fileUpload.nativeElement.value = '';
  }
}
