import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private snackbar: MatSnackBar,
  ) { }
  durationInSeconds = 5;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  openSnackbar(message:string, toastClass?: ToastClassEnum, duration?:number, vertical?: MatSnackBarVerticalPosition): void {
    this.snackbar.open(message, 'Close', {
      duration: (duration ?? this.durationInSeconds) * 1000,
      horizontalPosition: this.horizontalPosition,
      panelClass: [toastClass ?? ToastClassEnum.default],
      verticalPosition: (vertical ?? this.verticalPosition),
    });
  }
}
