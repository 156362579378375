import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UILandingPageComponent } from './pages/ui-landing/ui-landing-page.component';
import { Lvl2TestPageComponent } from './pages/lvl2-test/lvl2-test-page.component';
import { ExampleModule } from '../example/example.module';
import { AccountUpdateWorkflowModule } from '../account-request-workflows/account-update-workflow.module';


@NgModule({
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  declarations: [
    UILandingPageComponent,
    Lvl2TestPageComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ExampleModule,
    AccountUpdateWorkflowModule
  ],
  exports: [
  ]
})
export class UiExampleModule { }
