import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileMessageUploadModalComponent } from './modals/file-message-upload-modal/file-message-upload-modal.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FilesMessagesListComponent } from './component/files-messages-list/files-messages-list.component';
import { FilesMessagesPageComponent } from './pages/files-messages-page/files-messages-page.component';
import { FileMessageDetailsModalComponent } from './modals/file-message-details-modal/file-message-details-modal.component';

@NgModule({
  declarations: [
    FilesMessagesPageComponent,
    FileMessageUploadModalComponent,
    FilesMessagesListComponent,
    FileMessageDetailsModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class FilesMessagesModule { }
