import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SessionClient } from '../clients/generated/client';
import { Router } from '@angular/router';
import { PermissionsService } from './permissions.service';
import { Subscription, interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private cookieService: CookieService,
    private permissionsService: PermissionsService,
    private session: SessionClient,
    private router:Router,
  ) { }


  public isAuthenticated(): boolean {
    //expiration cookie is set server-side in the AuthExtensions.cs
    const expirationCookie = this.cookieService.get('.acmportal.expiration');

    if (!expirationCookie) return false;

    const expiration = new Date(expirationCookie);
    if (window.isNaN(expiration.getTime())) return false; // invalid date string in cookie

    return expiration > new Date();
  }

  pollSessionExpirationSubcription():Subscription {
    //called from portal.component.ts - check if session has expired every 5 seconds and redirect to login if past expiration.
    return interval(5000)
      .pipe()
      .subscribe(() => {
        if (this.isAuthenticated()) {
          return true;
        }
        console.log('Session Expired. Logging user out and redirecting to login page.');
        return this.router.navigateByUrl('/login');
      });
  }

  logout():void {
    this.permissionsService.clearPermissions();
    this.session.deleteSession().subscribe();
  }
}
