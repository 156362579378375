import { Component } from '@angular/core';
import { fadeIn } from 'src/app/shared/constants/animations';

@Component({
  animations: [fadeIn],
  selector: 'app-password-reset-requested-page',
  templateUrl: './password-reset-requested-page.component.html',
})
export class PasswordResetRequestedPageComponent  {
}
