import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomRoutes } from 'src/app/shared/models/custom-route.model';
import { CreateNewPasswordPageComponent } from './pages/create-new-password-page/create-new-password-page.component';
import { FirstMfaSetupPageComponent } from './pages/first-mfa-setup-page/first-mfa-setup-page.component';
import { FirstMfaVerificationPageComponent } from './pages/first-mfa-verification-page/first-mfa-verification-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MfaVerificationPageComponent } from './pages/mfa-verification-page/mfa-verification-page.component';
import { PasswordResetRequestedPageComponent } from './pages/password-reset-requested-page/password-reset-requested-page.component';
import { SignupPageComponent } from './pages/signup-page/signup-page.component';

const routes: CustomRoutes = [
  {
    path: 'login',
    component: LoginPageComponent,
    data: { title: 'Login' },
  },
  {
    path: 'signup',
    component: SignupPageComponent,
    data: { title: 'Signup' },
  },
  {
    path: 'mfa-verification',
    component: MfaVerificationPageComponent,
    data: { title: 'Verify Mobile Device' },
  },
  {
    path: 'first-mfa-setup',
    component: FirstMfaSetupPageComponent,
    data: { title: 'Setup MFA' },
  },
  {
    path: 'first-mfa-verification',
    component: FirstMfaVerificationPageComponent,
    data: { title: 'Verify Mobile Device' },
  },
  {
    path: 'password-reset-request',
    component: PasswordResetRequestedPageComponent,
    data: { title: 'Password Reset'}
  },
  {
    path: 'create-new-password',
    component: CreateNewPasswordPageComponent,
    data: { title: 'Create New Password'}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
