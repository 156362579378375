import { Injectable } from '@angular/core';
import { PermissionsService } from 'src/app/core/auth/permissions.service';
import { HtmlEnum, ResourceCenterModel, SectionEnum } from '../models/resource-center.models';
import { Permission } from 'src/app/core/clients/generated/client';

@Injectable({
  providedIn: 'root'
})
export class ResourceCenterService {

  constructor(private _permissionService: PermissionsService) { }

  get resourceCenterResources(): ResourceCenterModel[] {
    return this._resourceCenterResources.filter(r => r.Resolve ? r.Resolve() : true);
  }

  private _resourceCenterResources: ResourceCenterModel[] = [
    {
      Id: '1', //featured section, else empty
      Name: SectionEnum.ComplianceResources,
      BackgroundUrl: '/assets/images/resource-center/resource-center-compliance.png',
      /*Sso: {
        Name: 'Launch Orion Compliance',
        Url: 'api/sso/basis-code'
      },*/
      ViewMore: {
        HtmlId: HtmlEnum.ComplianceResources,
        Name: SectionEnum.ComplianceResources,
        SubSections: [
          {
            Id: '2', //sub-section
            Name: 'General',
            Assets: []
          },
          {
            Id: '16', //sub-section
            Name: 'DOL Resources',
            Assets: []
          },
        ],
        Expanded: true
      },
      Assets: [],
      Resolve: (): boolean => {
        return this._permissionService.hasOnePermission(Permission.ViewComplianceCentralSso);
      },
      IsFeatured: true,
    },
    {
      Id: '3', //featured section, else empty
      Name: SectionEnum.AlphastarSpecificPaperwork,
      BackgroundUrl: '/assets/images/resource-center/resource-center-acm.png',
      ViewMore: {
        Id: '4', //sub-section
        HtmlId: HtmlEnum.AlphastarSpecificPaperwork,
        Name: SectionEnum.AlphastarSpecificPaperwork,
        Assets: [],
        Expanded: false
      },
      Assets: [],
      Resolve: (): boolean => {
        return this._permissionService.hasOnePermission(Permission.ViewAlphastarForms) && !this._permissionService.hasOnePermission(Permission.ViewEvolvExperience);
      },
      IsFeatured: true,
    },
    {
      Id: '3', //featured section, else empty
      Name: SectionEnum.EvolvResources,
      BackgroundUrl: '/assets/images/resource-center/resource-center-evolv.png', //todo update!
      ViewMore: {
        Id: '4', //sub-section
        HtmlId: HtmlEnum.EvolvResources,
        Name: SectionEnum.EvolvResources,
        Assets: [],
        Expanded: false
      },
      Assets: [],
      Resolve: (): boolean => {
        return this._permissionService.hasOnePermission(Permission.ViewEvolvExperience);
      },
      IsFeatured: true,
    },
    {
      Id: '', //featured section, else empty
      Name: SectionEnum.CharlesSchwabForms,
      BackgroundUrl: '/assets/images/resource-center/resource-center-schwab.png',
      Sso: {
        Name: 'Visit Charles Schwab',
        Url: 'api/sso/schwab?destination=F_APPS'
      },
      ViewMore: {
        HtmlId: undefined,
        Name: undefined,
        SubSections: [],
        Expanded: false
      },
      Assets: [],
      Resolve: (): boolean => {
        return this._permissionService.hasOnePermission(Permission.ViewCharlesSchwabForms);
      },
      IsFeatured: true,
    },
    {
      Id: '19', //featured section, else empty
      Name: SectionEnum.OrionForms,
      BackgroundUrl: '/assets/images/resource-center/resource-center-orion.png',
      /*Sso: {
        Name: 'Launch Orion Compliance',
        Url: 'api/sso/basis-code'
      },*/
      ViewMore: {
        Id: '20', //view more
        HtmlId: HtmlEnum.OrionForms,
        Name: SectionEnum.OrionForms,
        Assets: [],
        Expanded: false
      },
      Assets: [],
      Resolve: (): boolean => {
        return this._permissionService.hasOnePermission(Permission.ViewOrionForms);
      },
      IsFeatured: true,
    },
    /*{
      Id: '11', //featured section, else empty
      Name: SectionEnum.CharlesSchwabForms,
      BackgroundUrl: '/assets/images/resource-center/resource-center-schwab.png',
      //Sso: {
      //  Name: 'Visit Charles Schwab',
      //  Url: 'https://si2.schwabinstitutional.com/SI2/SecAdmin/Logon.aspx?to=%2fSI2%2fHome%2fDefault.aspx'
      //},
      ViewMore: {
        HtmlId: HtmlEnum.CharlesSchwabForms,
        Name: SectionEnum.CharlesSchwabForms,
        SubSections: [
          {
            Id: '12',  //sub-section
            Name: 'Schwab New Accounts',
            Assets: []
          },
          {
            Id: '13',  //sub-section
            Name: 'Schwab Move Money',
            Assets: []
          },
        ],
        Expanded: false
      },
      Assets: [],
      Resolve: (): boolean => {
        return this._permissionService.hasOnePermission(Permission.ViewCharlesSchwabForms);
      },
      IsFeatured: true,
    },*/
    {
      Id: '',  //featured section, else empty
      Name: SectionEnum.InvestmentResources,
      BackgroundUrl: null,
      ViewMore: {
        Id: '18', //view more
        HtmlId: HtmlEnum.InvestmentResources,
        Name: SectionEnum.InvestmentResources,
        Assets: [],
        Expanded: false
      },
      Assets: [],
      Resolve: (): boolean => {
        return this._permissionService.hasOnePermission(Permission.ViewInvestmentResourceForms);
      },
      IsFeatured: false,
    },
    {
      Id: '', //featured section, else empty
      Name: SectionEnum.Erisa401kPlanForms,
      BackgroundUrl: null,
      ViewMore: {
        Id: '15', //sub-section
        HtmlId: HtmlEnum.Erisa401kPlanForms,
        Name: SectionEnum.Erisa401kPlanForms,
        Assets: [],
        Expanded: false
      },
      Assets: [],
      Resolve: (): boolean => {
        return this._permissionService.hasOnePermission(Permission.ViewErisa401kPlanForms);
      },
      IsFeatured: false,
    },
  ];
}