import { CurrencyPipe } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { DeliveryMethods, AllocationFrequencies } from 'src/app/portal/features/account-request-workflows/shared/constants/allocation-method-frequency.constant';
import { AccountUpdateStepEnum, RequestTypeEnum } from 'src/app/portal/features/account-request-workflows/shared/enums/account-update.enum';
import { ReviewModel } from 'src/app/portal/features/account-request-workflows/shared/models/review.model';
import { allocationAmountEqualValidator } from 'src/app/portal/features/account-request-workflows/shared/validators/allocation-amount-equal.validator';
import { allocationMinimumEntriesValidator } from 'src/app/portal/features/account-request-workflows/shared/validators/allocation-minimum-entries.validator';
import { notViewingCustomHoldingsValidator } from 'src/app/portal/features/account-request-workflows/shared/validators/not-viewing-custom-holdings.validator';
import { AccountRequestContributionModelBase, ContributionRequestsClient, FileParameter, } from '../../../../../core/clients/generated/client';
import { InvestmentRequestFormService } from '../../services/investment-request-form.service';
import { DeliveryMethodEnum } from '../../shared/enums/delivery-methods.enum';
import { TradeFrequencyEnum } from '../../shared/enums/trade-frequency.enum';
import { WireTransferRecipientEnum } from '../../shared/enums/wire-transfer-recipient.enum';
import { AllocationCustomHoldingSleeveFormArray, AllocationFormGroup } from '../../shared/models/allocation-form.models';
import { DeliveryOptionsFormGroup } from '../../shared/models/delivery-options-form.models';
import { AccountUpdateStepModel } from '../../shared/models/workflow.models';
import { customAllocationMinimumValidator } from '../../shared/validators/custom-allocation-minimum.validator';
import { ContributionFundsFormGroup } from '../models/contribute-funds-form-group';
import { ContributionFormGroup } from '../models/contribution-form.model';


@Injectable()
export class ContributionFormService extends InvestmentRequestFormService implements OnDestroy {
  private contributionFunds = this.fb.group({
    contributionAmount: [null, [Validators.required]],
    isSolicited: [true, [Validators.required]],
  }) as ContributionFundsFormGroup;

  private readonly allocationConfiguration = this.fb.group({
    sleeves: this.fb.array([], []),
    notes: this.fb.control(''),
    useCurrentSetup: false,
    sellAllEvenly: null,
    useProtectedCash: false,
    protectedCashAmount: this.fb.group({
      amount: [{
        value: null,
        disabled: true,
      }, Validators.required],
      fee: [{
        value: 0,
        disabled: true,
      }, Validators.required],
    }),
    showCustomHoldings: [false, [notViewingCustomHoldingsValidator(this.currentStep)]],
    customHoldings: this.fb.group({
      notes: null,
      allocation: this.fb.array([]),
      sellAllCustomHoldings: this.fb.control(null)
    }),
  }, {
    validators: [allocationMinimumEntriesValidator(), allocationAmountEqualValidator(() => this.initialAmount), customAllocationMinimumValidator(RequestTypeEnum.contribution)]
  }) as AllocationFormGroup;

  private readonly deliveryOptions = this.fb.group({
    deliveryMethod: [DeliveryMethodEnum.ach, [Validators.required]],
    tradeFrequency: [TradeFrequencyEnum.oneTime, [Validators.required]],
    initiationDate: [new Date(), [Validators.required]],
    files: null,
    isOvernightCheckDelivery: false,
    journalCashRecipientAccountNumber: ['', [Validators.required]],
    wireDeliveryOptionId: ['', [Validators.required]],
  }) as DeliveryOptionsFormGroup;

  readonly form = this.fb.group({
    contributionFunds: this.contributionFunds,
    allocationConfiguration: this.allocationConfiguration,
    deliveryOptions: this.deliveryOptions
  }) as ContributionFormGroup;

  readonly pages: AccountUpdateStepModel[] = [
    {
      formGroup: this.form.controls.contributionFunds,
      value: AccountUpdateStepEnum.step1,
    },
    {
      formGroup: this.form.controls.allocationConfiguration as AllocationFormGroup,
      value: AccountUpdateStepEnum.step2,
    },
    {
      formGroup: this.form.controls.deliveryOptions as DeliveryOptionsFormGroup,
      value: AccountUpdateStepEnum.step3,
    },
    {
      formGroup: this.form,
      value: AccountUpdateStepEnum.step4,
    }
  ];
  readonly useProtectedCashChange?: Subscription;
  readonly useCurrentSetupChange?: Subscription;
  readonly contributionAmountChange?: Subscription;
  readonly protectedCashAmountChange?: Subscription;
  readonly allocationHeader = '';
  readonly allocationFooter = 'Contribution Amount';
  get allocationAmountNotEqualError(): string {
    return `Your allocations do not add up to the contribution amount of ${this.currencyPipe.transform(this.initialAmount) ?? 0}`;
  }

  constructor(fb: UntypedFormBuilder, private contributionRequestsClient: ContributionRequestsClient, private currencyPipe: CurrencyPipe) {
    super(fb);

    this.useProtectedCashChange = this.form.controls.allocationConfiguration.controls.useProtectedCash?.valueChanges
      .pipe(distinctUntilChanged()).subscribe({
        next: (useProtectedCash: boolean) => {
          this.toggleUseProtectedCash(useProtectedCash);
        }
      });

    this.useCurrentSetupChange = this.form?.controls.allocationConfiguration.controls.useCurrentSetup.valueChanges
      .pipe(
        distinctUntilChanged())
      .subscribe(value => {
        this.useCurrentSetupChanged(value as boolean);
      });

    this.contributionAmountChange = this.form?.controls.contributionFunds.controls.contributionAmount.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(250))
      .subscribe(() => {
        this.initSleeveInput();
      });


    this.protectedCashAmountChange = this.form?.controls.allocationConfiguration.controls?.protectedCashAmount?.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(250))
      .subscribe(() => {
        this.initSleeveInput();
      });
  }

  get protectedCashAmount(): number {
    return this.allocationFormGroup.value.protectedCashAmount?.amount || 0;
  }

  get customHoldingAllocations(): AllocationCustomHoldingSleeveFormArray {
    return this.allocationFormGroup.controls.customHoldings.controls.allocation;
  }

  get showCustomHoldings(): boolean {
    return this.allocationFormGroup.value.showCustomHoldings;
  }

  get contributeFundsFormGroup(): ContributionFundsFormGroup {
    return this.form?.controls.contributionFunds as ContributionFundsFormGroup;
  }

  get initialAmount(): number {
    return this.contributeFundsFormGroup?.value.contributionAmount as number;
  }

  get allocationFormGroup(): AllocationFormGroup {
    return this.form?.controls.allocationConfiguration as AllocationFormGroup;
  }

  get deliveryOptionsFormGroup(): DeliveryOptionsFormGroup {
    return this.form?.controls.deliveryOptions as DeliveryOptionsFormGroup;
  }

  toggleUseProtectedCash(useProtectedCash: boolean): void {
    if (useProtectedCash) {
      this.allocationFormGroup.controls.protectedCashAmount?.enable();
    } else {
      this.allocationFormGroup.controls.protectedCashAmount?.disable();
    }
  }

  initSleeveInput(): void {
    if (this.form?.controls?.allocationConfiguration?.value?.useCurrentSetup) {
      this.useCurrentSetupChanged(this.form?.controls?.allocationConfiguration?.value?.useCurrentSetup as boolean);
    }
  }

  useCurrentSetupChanged(useCurrentSetup: boolean): void {
    if (useCurrentSetup) {
      this.customHoldingAllocations?.clear();
      const existingCustomSleeveIndex = this.sleevesFormArray.value.findIndex(sleeve => sleeve.isCustomSleeve);
      if (existingCustomSleeveIndex >= 0) this.sleevesFormArray.removeAt(existingCustomSleeveIndex);
      this.setSleeveAmountProportionally(this.sleevesFormArray, this.initialAmount, this.protectedCashAmount);
      this.sleevesFormArray.disable();
    } else {
      const existingCustomSleeve = this.sleevesFormArray.value.find(sleeve => sleeve.isCustomSleeve);
      if (!existingCustomSleeve && this.customSleeve) this.sleevesFormArray.push(this.customSleeve);
      this.setSleeveAmountsToNull(this.sleevesFormArray);
      this.sleevesFormArray.enable();
    }
  }

  postRequest(investmentAccountID: number): Observable<number> {
    const model: AccountRequestContributionModelBase = {
      investmentAccountID: investmentAccountID,
      isSolicited: this.contributeFundsFormGroup.value.isSolicited,
      requestDetail: {
        frequencyID: this.deliveryOptionsFormGroup.value.tradeFrequency,
        grossAmount: this.contributeFundsFormGroup.value.contributionAmount,
        startDate: this.deliveryOptionsFormGroup.value.initiationDate,
        deliveryMethodID: this.deliveryOptionsFormGroup.controls.deliveryMethod.value as number,
        notes: this.allocationFormGroup.value.notes,
        protectedCash: this.allocationFormGroup.value.protectedCashAmount,
        isCustomAllocation: !this.allocationFormGroup.value.useCurrentSetup,
        requestSleeves: this.allocationFormGroup.controls.sleeves.value.filter(x => x.amount).map((x) => {
          return {
            investmentAccountModelID: x.id,
            accountNumber: x.accountNumber,
            amount: x.amount,
            accountTotalFee: undefined,
            isNewSleeve: undefined,
          };
        }),
      },
      tradeInstruction: (this.allocationFormGroup.value.customHoldings.allocation.length || this.allocationFormGroup.value.customHoldings.notes?.length) ? {
        tradeInstructionID: 3,
        notes: this.allocationFormGroup.value.customHoldings.notes,
        tradeInstructionOptions: this.allocationFormGroup.value.customHoldings.allocation.map((x) => {
          return {
            tradeInstructionOptionID: x.action.tradeInstructionOptionID,
            symbol: x.stockSymbol,
            amount: x.amount
          };
        })
      } : undefined
    };

    const deliveryOptionsFormGroup = this.form.controls.deliveryOptions as DeliveryOptionsFormGroup;
    const files: FileParameter[] = deliveryOptionsFormGroup.value.files?.map(file => {
      return { data: file, fileName: file.name };
    }) || [];

    return this.contributionRequestsClient.createContributionRequest(files, model);
  }

  getReviewModel(): ReviewModel {
    const amountFormGroup = this.form.controls.contributionFunds as ContributionFundsFormGroup;
    const allocationConfigurationFormGroup = this.form.controls.allocationConfiguration as AllocationFormGroup;
    const deliveryOptionsFormGroup = this.form.controls.deliveryOptions as DeliveryOptionsFormGroup;
    const customHoldingsAmount = allocationConfigurationFormGroup.controls.sleeves.value.find(x => x.isCustomSleeve)?.amount;

    return {
      amount: amountFormGroup.value.contributionAmount,
      protectedCash: allocationConfigurationFormGroup.value.protectedCashAmount,
      sleeves: allocationConfigurationFormGroup.controls.sleeves.value.filter(x => !x.isCustomSleeve && x.amount).map((x) => {
        return {
          name: x.model,
          amount: x.amount,
          percent: x.amount / amountFormGroup.value.contributionAmount
        };
      }),
      customHoldings: customHoldingsAmount ? {
        amount: customHoldingsAmount,
        percent: customHoldingsAmount / amountFormGroup.value.contributionAmount,
        holdings: allocationConfigurationFormGroup.value.customHoldings.allocation.map((x) => {
          return {
            name: x.action.isStock ? x.stockSymbol : x.action.name,
            type: x.type,
            amount: (!x.action.isStock && x.action.allocationAmountType === 'Dollar') ? x.amount : undefined,
            shareCount: (x.action.isStock || x.action.allocationAmountType === 'Share') ? x.amount : undefined,
          };
        }),
        notes: allocationConfigurationFormGroup.value.customHoldings.notes,
      } : undefined,
      delivery: {
        method: DeliveryMethods.find(x => x.value === deliveryOptionsFormGroup.controls.deliveryMethod.value)?.name as string,
        methodEnum: deliveryOptionsFormGroup.controls.deliveryMethod.value as DeliveryMethodEnum,
        frequency: AllocationFrequencies.find(x => x.value === deliveryOptionsFormGroup.value.tradeFrequency)?.name as string,
        startDate: deliveryOptionsFormGroup.value.initiationDate,
        journalAccountNumber: deliveryOptionsFormGroup.value.journalCashRecipientAccountNumber,
        wireTransferTo: WireTransferRecipientEnum[deliveryOptionsFormGroup.value.wireDeliveryOptionId] as string
      },
      notes: allocationConfigurationFormGroup.value.notes,
      files: deliveryOptionsFormGroup.value.files
    };
  }

  // Since this is a scoped service it will be destroyed when the component it is associated with is destroyed
  ngOnDestroy(): void {
    this.useProtectedCashChange?.unsubscribe();
    this.useCurrentSetupChange?.unsubscribe();
    this.contributionAmountChange?.unsubscribe();
    this.protectedCashAmountChange?.unsubscribe();
  }
}
