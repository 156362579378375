import { Component, Input, OnInit } from '@angular/core';
import { CloseAccountFormGroup } from '../../models/close-account-form.model';
import { DeliveryMethodEnum } from '../../../shared/enums/delivery-methods.enum';
import { CloseAccountReviewModel } from '../../models/close-account-review.model';
import { DeliveryMethods } from '../../../shared/constants/allocation-method-frequency.constant';
import { WireTransferRecipientEnum } from '../../../shared/enums/wire-transfer-recipient.enum';

@Component({
  selector: 'app-close-account-review-step',
  templateUrl: './close-account-review-step.component.html',
})
export class CloseAccountReviewStepComponent implements OnInit {
  @Input() parentForm?: CloseAccountFormGroup;
  reviewModel?: CloseAccountReviewModel;
  deliveryMethodEnum = DeliveryMethodEnum;

  ngOnInit(): void {
    this.reviewModel = this.getReviewModel();
  }

  getReviewModel(): CloseAccountReviewModel {

    const deliveryMethod = DeliveryMethods.find(x => x.value === this.parentForm?.value.deliveryOptions.deliveryMethod)?.name;

    const deliveryMethodDetail =  this.parentForm?.value.deliveryOptions.deliveryMethod === this.deliveryMethodEnum.journalCash ? ' (' + this.parentForm?.value.deliveryOptions.journalCashRecipientAccountNumber +')' : this.parentForm?.value.deliveryOptions.deliveryMethod === this.deliveryMethodEnum.wireTransfer ? ' (' + WireTransferRecipientEnum[this.parentForm?.value.deliveryOptions.wireDeliveryOptionId as number] +')' : this.parentForm?.value.deliveryOptions.deliveryMethod === this.deliveryMethodEnum.check ? this.parentForm?.value.deliveryOptions.isOvernightCheckDelivery ? ' (Overnight Delivery)' : '' : '';

    return {
      notes: this.parentForm?.value.reason as string,
      billAccountOnClosing: this.parentForm?.value.billAccountOnClosing as boolean,
      taxWithholding: {
        federalPercent: this.parentForm?.value.federalTaxAmount as number,
        statePercent: this.parentForm?.value.stateTaxAmount as number,
      },
      deliveryMethod: deliveryMethod + (deliveryMethodDetail ? deliveryMethodDetail as string : ''),
      files: this.parentForm?.value.deliveryOptions.files as File[],
    };
  }
}
