import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { AccountUpdateMainModalComponent } from './modals/account-update-main-modal/account-update-main-modal.component';
import { ContributionRequestComponent } from './contribution-request/contribution-request.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DistributionRequestComponent } from './distribution-request/distribution-request.component';
import { CloseAccountRequestComponent } from './close-account-request/close-account-request.component';
import { CustomHoldingsComponent } from './shared/components/allocation/custom-holdings/custom-holdings.component';
import { CustomHoldingsAllocationComponent } from './shared/components/allocation/custom-holdings/custom-holdings-allocation/custom-holdings-allocation.component';
import { ReviewStepComponent } from './shared/components/review-step/review-step.component';
import { PlanningDeskRequestComponent } from './planning-desk-request/planning-desk-request.component';
import { AllocationSummaryComponent } from './shared/components/allocation/allocation-summary/allocation-summary.component';
import { SolicitationOptionsComponent } from './shared/components/solicitation-options/solicitation-options.component';
import { GeneralInfoStepComponent } from './planning-desk-request/components/general-info-step/general-info-step.component';
import { AssetDetailsStepComponent } from './planning-desk-request/components/asset-details-step/asset-details-step.component';
import { PlanningDeskReviewStepComponent } from './planning-desk-request/components/planning-desk-review-step/planning-desk-review-step.component';
import { ReviewRowEntryComponent } from './planning-desk-request/components/review-row-entry/review-row-entry.component';
import { DistributeFundsComponent } from './distribution-request/components/distribute-funds/distribute-funds.component';
import { AllocationAddSleeveComponent } from './shared/components/allocation/allocation-add-sleeve/allocation-add-sleeve.component';
import { ReallocationRequestComponent } from './reallocation-request/reallocation-request.component';
import { DeliveryMethodsComponent } from './shared/components/delivery-methods/delivery-methods.component';
import { AllocationStepComponent } from './shared/components/allocation/allocation-step.component';
import { AllocationInputComponent } from './shared/components/allocation/allocation-input/allocation-input.component';
import { ContributeFundsComponent } from './contribution-request/components/contribute-funds/contribute-funds.component';
import { AccountRequestModalHeaderComponent } from './shared/components/account-request-modal-header/account-request-modal-header.component';
import { TaxOptionsComponent } from './shared/components/tax-options/tax-options/tax-options.component';
import { AllocationNotesComponent } from './shared/components/allocation/allocation-notes/allocation-notes.component';
import { CustomHoldingsNotesComponent } from './shared/components/allocation/custom-holdings/custom-holdings-notes/custom-holdings-notes.component';
import { CompleteComponent } from './shared/components/complete/complete.component';
import { CloseAccountReviewStepComponent } from './close-account-request/components/close-account-review-step/close-account-review-step.component';

@NgModule({
  declarations: [
    AccountUpdateMainModalComponent,
    ContributionRequestComponent,
    DistributionRequestComponent,
    ReallocationRequestComponent,
    CloseAccountRequestComponent,
    CustomHoldingsComponent,
    CustomHoldingsAllocationComponent,
    AllocationStepComponent,
    ReviewStepComponent,
    DeliveryMethodsComponent,
    ContributeFundsComponent,
    SolicitationOptionsComponent,
    PlanningDeskRequestComponent,
    AllocationStepComponent,
    AllocationSummaryComponent,
    AllocationInputComponent,
    GeneralInfoStepComponent,
    AssetDetailsStepComponent,
    PlanningDeskReviewStepComponent,
    ReviewRowEntryComponent,
    DistributeFundsComponent,
    AllocationAddSleeveComponent,
    AccountRequestModalHeaderComponent,
    TaxOptionsComponent,
    AllocationNotesComponent,
    CustomHoldingsNotesComponent,
    CompleteComponent,
    CloseAccountReviewStepComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  providers: [
    CurrencyPipe
  ]
})
export class AccountUpdateWorkflowModule { }
