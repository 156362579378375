import { AbstractControl, ValidationErrors } from '@angular/forms';

/** Check for a 6-digit numerical value for MFA PIN
 * To check for this error, use hasError('invalidMFa')
*/
export function ValidateMfaPin(control: AbstractControl<string>): ValidationErrors | null {
  if (!control.value) return null;

  const isSixDigit = String(control.value).length === 6;
  // Try to convert string to a number and check if that is a valid number
  const isNumber = !isNaN(Number(control.value));
  const error = {
    invalidMFa: {
      value: String(control.value)
    }
  };

  return (isNumber && isSixDigit) ? null : error;
}