import { Component, Input, OnInit } from '@angular/core';
import { InvestmentRequestFormService } from '../../../services/investment-request-form.service';
import { RequestTypeEnum } from '../../enums/account-update.enum';
import { DeliveryMethodEnum } from '../../enums/delivery-methods.enum';
import { ReviewModel } from '../../models/review.model';

@Component({
  selector: 'app-review-step',
  templateUrl: './review-step.component.html',
})
export class ReviewStepComponent implements OnInit {
  @Input() requestType?: RequestTypeEnum;
  @Input() investmentRequestFormService?: InvestmentRequestFormService;
  reviewModel?: ReviewModel;
  requestTypeEnum = RequestTypeEnum;
  deliveryMethodEnum = DeliveryMethodEnum;

  ngOnInit(): void {
    if (this.requestType) this.reviewModel = this.investmentRequestFormService?.getReviewModel();
  }
}