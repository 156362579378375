import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionsService } from 'src/app/core/auth/permissions.service';
import { Permission } from 'src/app/core/clients/generated/client';

@Directive({
  selector: '[appShowIfAll]'
})
export class ShowIfAllDirective {

  private hasView = false;

  constructor(private templateRef:TemplateRef<any>, private viewContainerRef:ViewContainerRef, private permissionService:PermissionsService) { }

  @Input() set appShowIfAll(permissions:Permission|Permission[]) {

    permissions = Array.isArray(permissions) ? permissions : [permissions];
    const isAuthorized = this.permissionService.hasAllPermissions(permissions);
    if(isAuthorized && !this.hasView){
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!isAuthorized && this.hasView) {
      this.viewContainerRef.clear();
      this.hasView = false;
    }
  }
}
