import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AccountMessageModel, AccountSearchListModel, FileParameter, AccountMessageRequestTypeEnum, PlanningDeskMessageModel } from 'src/app/core/clients/generated/client';
import { FilterCheckbox } from '../../request-tracker/models/request-tracker-models';
import { Account } from '../../accounts/models/account.models';

export interface AccountMessagePostModel {
  accountMessageModel: AccountMessageModel;
  files: FileParameter[];
}

export interface PlanningDeskMessagePostModel {
  accountMessageModel: PlanningDeskMessageModel;
  files: FileParameter[];
}

export interface FileMessageUploadModalModel {
  account: Account | undefined;
  onBehalfOfRequest: FileMessageUploadModalAttachedRequest | undefined;
}

export interface FileMessageUploadModalAttachedRequest {
  requestId: number | undefined;
  requestType: AccountMessageRequestTypeEnum | undefined;
  requestTitle: string | undefined;
}

export interface Recipient {
  recipientId: number;
  recipientName: string;
}

export interface AdvisorMessageModel {
  account: AccountSearchListModel;
  recipient: Recipient;
  message: string;
  files: File[];
}

export interface AdvisorMessageFormGroup extends UntypedFormGroup {
  value: AdvisorMessageModel;
  controls: {
    account: UntypedFormControl;
    recipient: UntypedFormControl;
    message: UntypedFormControl;
    files: UntypedFormControl;
  }
}

export class FileMessagesFilter {
  Advisors: FilterCheckbox[] = [];
}