import { Component, OnInit } from '@angular/core';
import { fadeIn } from 'src/app/shared/constants/animations';

@Component({
  animations: [fadeIn],
  selector: 'app-notification-banner',
  templateUrl: './notification-banner.component.html',
})
export class NotificationBannerComponent implements OnInit{

  showNotifications = false; //hides banner

  webinar0906 = {
    Show: false,
    Date: new Date(2023, 8, 6, 9, 30),  //Sept 6, 2023 @ 9:30 AM
  };

  webinar0907 = {
    Show: false,
    Date: new Date(2023, 8, 7, 13, 0),  //Sept 7, 2023 @ 1:00 PM
  };

  constructor() { }

  ngOnInit(): void {
    const date = new Date();

    if (date <= this.webinar0906.Date){
      this.showNotifications = true;
      this.webinar0906.Show = true;
    } else if (date <= this.webinar0907.Date){
      this.showNotifications = true;
      this.webinar0907.Show = true;
    }
  }
}
