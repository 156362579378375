import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { RequestTypeEnum } from '../enums/account-update.enum';
import { AllocationCustomHoldingSleeveModel, AllocationFormGroup, AllocationSleeveModel } from '../models/allocation-form.models';

export const customAllocationMinimumValidator = (requestType: RequestTypeEnum): ValidatorFn => {
  return (form: AbstractControl): ValidationErrors | null => {
    const allocationForm = form as AllocationFormGroup;
    const customHoldings = allocationForm.controls.sleeves?.value?.find(sleeve => sleeve.isCustomSleeve);
    const customHoldingAllocations = allocationForm.controls.customHoldings?.value?.allocation;

    //if reallocation is for only making trades, ignore validation
    if ((allocationForm.controls.isTradesOnly && allocationForm.controls.isTradesOnly.value === true) && !hasAllocatedCustomHoldings(customHoldingAllocations)){
      return { needsCustomHoldingAllocation: true };
    }


    if (!hasCustomHoldings(customHoldings)) {
      //just bypass this validation for now
      return null;
    }

    if (useAvailableCash(customHoldings)) {
      return null;
    }
    if (hasCustomHoldingsChanges(requestType, customHoldings)) {
      return null;
    }
    if (hasAllocatedCustomHoldings(customHoldingAllocations)) {
      return null;
    }
    return { needsCustomHoldingAllocation: true };
  };
};

const hasCustomHoldings = (customHoldings: AllocationSleeveModel | undefined): boolean =>
  !!customHoldings?.amount;

const useAvailableCash = (customHoldings: AllocationSleeveModel | undefined): boolean =>
  !!customHoldings?.useAvailableCash;

const hasCustomHoldingsChanges = (requestType: RequestTypeEnum, customHoldings: AllocationSleeveModel | undefined): boolean =>
  requestType === RequestTypeEnum.reallocation && customHoldings?.currentValue === customHoldings?.amount;

const hasAllocatedCustomHoldings = (customHoldingAllocations: AllocationCustomHoldingSleeveModel[]): boolean =>
  customHoldingAllocations.length > 0;

export const needsCustomHoldingAllocation = 'needsCustomHoldingAllocation';