export enum InvestmentAccountTypeEnum {
  individual = 1,
  trust = 3,
  estate = 4,
  traditionalIRA = 5,
  simpleIRA = 6,
  beneficiaryIRS = 7,
  rothIRA = 8,
  SEP_IRA = 9,
  rolloverIRA = 11,
  k401 = 12,
  k401Roth = 13,
  corporation = 14,
  coverdellESA = 15,
  individualTOD = 16,
  limitedLiabilityCompany = 17,
  profitSharingPlan = 18,
  retirementTrust = 19,
  UGMA_UTMA = 20,
  jointTOD = 21,
  beneficiaryRothIRA = 22,
  JTWROS = 24,
  taxExempt = 25,
  variableAnnuity = 26,
  jointTennantsInCommon = 27,
  tenantsByTheEntirety = 28,
  JTWROS_TOD = 29,
  guardianship = 30,
  guardianshipTOD = 31,
  a401 = 32,
  b401 = 33,
  k401Plan = 34,
  financialPlan = 35,
  minorIRA = 36,
  minorRothIRA = 37,
  conservatorship = 38,
  jointCommunityProperty = 39,
  definedBenefitPlan = 40,
  partnership = 41,
  k401Plan321 = 42,
  k401Plan338 = 43,
  capitalAppreciation = 44,
  fixedIndexAnnuity = 45
}