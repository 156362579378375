import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { customHoldingsModelID } from 'src/app/portal/features/account-request-workflows/shared/constants/custom-holdings-model-id.constant';
import { AccountUpdateStepEnum } from 'src/app/portal/features/account-request-workflows/shared/enums/account-update.enum';
import { AccountModel } from '../../../../core/clients/generated/client';
import { ContributionFormGroup } from '../contribution-request/models/contribution-form.model';
import { DistributionFormGroup } from '../distribution-request/models/distribution-form.model';
import { ReallocationFormGroup } from '../reallocation-request/models/reallocation-form.models';
import { AllocationSleeveFormGroup, AllocationSleeveFormArray, AllocationCustomHoldingsFormGroup, AllocationCustomHoldingSleeveFormGroup, NewSleeveFormGroup } from '../shared/models/allocation-form.models';
import { ReviewModel } from '../shared/models/review.model';
import { AccountUpdateStepModel, TradeInstructionOptionModel } from '../shared/models/workflow.models';

@Injectable()
export abstract class InvestmentRequestFormService {
  abstract form: ReallocationFormGroup | DistributionFormGroup | ContributionFormGroup;
  abstract allocationHeader: string;
  abstract allocationFooter: string;
  abstract allocationAmountNotEqualError: string;
  abstract pages: AccountUpdateStepModel[];
  currentStep = AccountUpdateStepEnum.step1;
  public showValidationErrorPanel = false;
  customSleeve?: AllocationSleeveFormGroup;
  account?: AccountModel;
  isSolicited = false;  //values passed in from account-update-main-modal

  constructor(public fb: UntypedFormBuilder) { }

  abstract get initialAmount(): number;

  get sleevesFormArray(): AllocationSleeveFormArray {
    return this.form?.controls.allocationConfiguration.controls.sleeves as AllocationSleeveFormArray;
  }

  get customHoldings(): AllocationCustomHoldingsFormGroup {
    return this.form?.controls.allocationConfiguration.controls.customHoldings as AllocationCustomHoldingsFormGroup;
  }

  abstract getReviewModel(): ReviewModel

  goToNextStep(formGroup: UntypedFormGroup, lastStep: number): void {
    if (formGroup.invalid) {

      this.showValidationErrorPanel = true;

      //auto scroll dialog to top of form by finding element named 'top-of-form' to show validation summary div
      const element = document.getElementById('top-of-form');
      if (element) {
        element.scrollIntoView();
      }

      formGroup.markAllAsTouched();
    } else {
      if ((this.currentStep < lastStep)) {
        this.showValidationErrorPanel = false;
        this.currentStep++;
      }
    }
  }

  goToPreviousStep(): void {
    if (this.currentStep > AccountUpdateStepEnum.step1) {
      this.showValidationErrorPanel = false;
      this.currentStep--;
    }
  }

  addSleeves(account: AccountModel): void {
    if (account.sleeves.length > 0) {
      account.sleeves.forEach(sleeve => {
        this.sleevesFormArray.push(this.fb.group({
          id: [sleeve.investmentAccountModelID],
          model: [sleeve.investmentAccountModel],
          accountNumber: [sleeve.accountNumber],
          amount: [null],
          percentage: [null],
          currentValue: [sleeve.currentValue],
          cashValue:  [sleeve.cashValue],
          accountTotalFee: [sleeve.accountTotalFee * 100],
          isCustomSleeve: sleeve.investmentAccountModelID === customHoldingsModelID,
          isNewSleeve: false,
          useAvailableCash: sleeve.investmentAccountModelID === customHoldingsModelID
        }));
      });
    } else {
      this.sleevesFormArray?.push(this.fb.group({
        id: [account.investmentAccountModelID],
        accountNumber: [account.accountNumber],
        model: [account.investmentAccountModel],
        amount: [null],
        percentage: [null],
        accountTotalFee: [(account.totalFee || 0) * 100],
        currentValue: [account.accountValue || 0],
        cashValue:  [account.cashValue || 0],
        isCustomSleeve: account.investmentAccountModelID === customHoldingsModelID,
        isNewSleeve: false,
        //TODO: Temp. bypass custom holdings detail allocation requirement by setting this to true for custom holdings
        useAvailableCash: account.investmentAccountModelID === customHoldingsModelID
      }));
    }
    this.customSleeve = this.sleevesFormArray.controls.find(sleeve => sleeve.value.isCustomSleeve);
  }

  addSleeve(sleeve: NewSleeveFormGroup): void {
    this.sleevesFormArray.push(this.fb.group({
      id: sleeve.value.model.id,
      model: sleeve.value.model.name,
      amount: [sleeve.value.amount, [Validators.required]],
      percentage: [sleeve.value.percentage],
      currentValue: 0,
      cashValue: 0,
      accountNumber: [null],
      accountTotalFee: [sleeve.value.accountTotalFee, [Validators.required]],
      isCustomSleeve: sleeve.value.model.id === customHoldingsModelID,
      isNewSleeve: true,
      useAvailableCash: sleeve.value.model.id === customHoldingsModelID,
    }) as AllocationSleeveFormGroup);
  }

  removeSleeve(index: number): void {
    this.sleevesFormArray.removeAt(index);
  }

  setSleeveAmountProportionally(sleevesFormArray: AllocationSleeveFormArray, initialAmount: number, protectedCashAmount: number): void {
    const sleeveTotalAmount = sleevesFormArray.value.reduce((acc, cv) => acc + cv.currentValue, 0) ?? 1;
    sleevesFormArray.controls.forEach(sleeve => {
      if (initialAmount && protectedCashAmount < initialAmount) {
        const amountPercentage = (sleeve.value.currentValue >= 0 ? sleeve.value.currentValue : 0) / sleeveTotalAmount;
        const amount = amountPercentage * (initialAmount - protectedCashAmount);
        sleeve.patchValue({ amount: amount });
      } else sleeve.patchValue({ amount: 0 });
    });
  }

  setSleeveAmountsEvenly(sleevesFormArray: AllocationSleeveFormArray, initialAmount: number): void {
    const sleeveLength = sleevesFormArray.controls.length;
    sleevesFormArray.controls.forEach(sleeve => {
      if (initialAmount) {
        const amount = initialAmount / sleeveLength;
        sleeve.patchValue({ amount: amount });
      }
    });
  }

  setSleeveAmountsToNull(sleevesFormArray: AllocationSleeveFormArray): void {
    sleevesFormArray.controls.forEach(sleeve => {
      sleeve.patchValue({ amount: null });
    });
  }

  /* removing for now so the advisor goes in with blank slate for proposed new values.
  setSleeveAmountsToCurrentValue(sleevesFormArray: AllocationSleeveFormArray): void {
    sleevesFormArray.controls.forEach(sleeve => {
      sleeve.patchValue({ amount: sleeve.value.currentValue });
    });
  }*/

  addNewCustomAllocation(allocationSleeve: AllocationCustomHoldingSleeveFormGroup): void {
    const newAllocation = this.fb.group({
      action: [allocationSleeve.value.action, Validators.required],
      stockSymbol: [allocationSleeve.value.stockSymbol, Validators.required],
      amount: [allocationSleeve.value.amount, [Validators.required, Validators.min(1)]],
      type: [allocationSleeve.value.type]
    }) as AllocationCustomHoldingSleeveFormGroup;
    if ((newAllocation.controls.action.value as TradeInstructionOptionModel).isSellAll) newAllocation.controls.amount.disable();
    this.customHoldings?.controls.allocation.push(newAllocation);
  }

  removeCustomAllocation(index: number): void {
    this.customHoldings.controls.allocation.removeAt(index);
  }
}
