import { Component } from '@angular/core';
import { ExampleStoreService } from 'src/app/core/stores/example-store.service';

@Component({
  selector: 'app-teams-page',
  templateUrl: './teams-page.component.html',
})
export class TeamsPageComponent {
  selectedTeam:number = this.exampleStoreService.SelectedTeamId;

  constructor(private exampleStoreService:ExampleStoreService) { }

  changeTeam(event:number):void{
    this.exampleStoreService.SelectedTeamId = event;
    this.selectedTeam = event;
  }

}
