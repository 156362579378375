// to help keep files small, we've attempted the practice of storing static arrays in their own files

import { Player } from '../models/player';

export const Players: Player[] = [
  {
    Id: 1,
    FirstName: 'Kevin',
    LastName: 'Durant',
    TeamId: 1,
    Number: 35,
    ImageUrl: 'https://cdn.nba.com/headshots/nba/latest/1040x760/201142.png'
  },
  {
    Id: 2,
    FirstName: 'Lebron',
    LastName: 'James',
    TeamId: 2,
    Number: 6,
    ImageUrl: 'https://cdn.nba.com/headshots/nba/latest/1040x760/2544.png'
  },
  {
    Id: 3,
    FirstName: 'James',
    LastName: 'Harden',
    TeamId: 1,
    Number: 13,
    ImageUrl: 'https://cdn.nba.com/headshots/nba/latest/1040x760/201935.png'
  },
  {
    Id: 4,
    FirstName: 'Anthony',
    LastName: 'Davis',
    TeamId: 2,
    Number: 3,
    ImageUrl: 'https://cdn.nba.com/headshots/nba/latest/1040x760/203076.png'
  },
  {
    Id: 5,
    FirstName: 'Russel',
    LastName: 'Westbrook',
    TeamId: 2,
    Number: 0,
    ImageUrl: 'https://cdn.nba.com/headshots/nba/latest/1040x760/201566.png'
  },
  {
    Id: 6,
    FirstName: 'Carmelo',
    LastName: 'Anthony',
    TeamId: 2,
    Number: 15,
    ImageUrl: 'https://cdn.nba.com/headshots/nba/latest/1040x760/2546.png'
  },
  {
    Id: 7,
    FirstName: 'Kyrie',
    LastName: 'Irving',
    TeamId: 1,
    Number: 11,
    ImageUrl: 'https://cdn.nba.com/headshots/nba/latest/1040x760/202681.png'
  }
];