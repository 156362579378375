import { Component, Input } from '@angular/core';
import { AcmMediaProject } from 'src/app/core/clients/generated/client';
import { DevelopmentCenterWistiaSection } from '../../models/development-center.models';

@Component({
  selector: 'app-development-center-wistia-section',
  templateUrl: './development-center-wistia-section.component.html',
})
export class DevelopmentCenterWistiaSectionComponent {
  @Input() model?: DevelopmentCenterWistiaSection;
  @Input() wistiaProject?: AcmMediaProject;
}