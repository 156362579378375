import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AcmMediaProject,  WistiaClient } from 'src/app/core/clients/generated/client';
import { WistiaProjectsEnum } from 'src/app/shared/enums/wistia.enum';
import { getWistiaSection } from 'src/app/shared/helpers/wistiaSection.helpers';
import { DevelopmentCenterWistiaSection, DevelopmentCenterWistiaSectionEnum } from '../../models/development-center.models';

@Component({
  selector: 'app-next-level',
  templateUrl: './next-level-page.component.html',
})
export class NextLevelPageComponent implements OnInit {
  constructor (
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private wistiaApiService: WistiaClient,
  ) { }
  wistiaSections: DevelopmentCenterWistiaSection[] = [];
  wistiaProject?: AcmMediaProject;

  ngOnInit(): void {
    this.titleService.setTitle(this.activatedRoute.snapshot.data['title'] + ' | Alphastar Portal');
    this.wistiaApiService.getProjectAssets(WistiaProjectsEnum.DevelopmentCenter).subscribe({
      next: result => {
        this.wistiaProject = result;
        const topicalVideos: DevelopmentCenterWistiaSection | undefined = getWistiaSection(this.wistiaProject, DevelopmentCenterWistiaSectionEnum.NextLevelTopical);
        if (topicalVideos) {
          this.wistiaSections.push(topicalVideos);
        }
        const previousEvents: DevelopmentCenterWistiaSection | undefined = getWistiaSection(this.wistiaProject, DevelopmentCenterWistiaSectionEnum.NextLevelPreviousEvents);
        if (previousEvents) {
          this.wistiaSections.push(previousEvents);
        }
        const digitalResources: DevelopmentCenterWistiaSection | undefined = getWistiaSection(this.wistiaProject, DevelopmentCenterWistiaSectionEnum.NextLevelDigitalResources);
        if (digitalResources) {
          this.wistiaSections.push(digitalResources);
        }
      }
    });
  }
}
