/**
 * @param (accountSelection) used for kickoff step
 * @param (contribution) used to determine contribution workflow
 * @param (accountSelection) used to determine distribution workflow
 * @param (reallocation) used to determine model-change/reallocation workflow
 * @param (closeAccount) used to determineclose account workflow
 */
export enum RequestTypeEnum {
  accountSelection,
  closeAccount,
  contribution,
  distribution,
  planningDesk,
  reallocation,
}

export enum AccountUpdateStepEnum {
  step1 = 1,
  step2 = 2,
  step3 = 3,
  step4 = 4,
  step5 = 5,
}