import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PermissionsService } from 'src/app/core/auth/permissions.service';
import { FirstMfaSetupClient, PermissionsClient } from 'src/app/core/clients/generated/client';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { ValidateMfaPin } from 'src/app/shared/validators/login.validator';

@Component({
  selector: 'app-first-mfa-verification-form',
  templateUrl: './first-mfa-verification-form.component.html',
})
export class FirstMfaVerificationFormComponent {

  firstMfaVerificationForm = this.formBuilder.group({
    mfaCode: this.formBuilder.control<string>('', [Validators.required, ValidateMfaPin]),
    rememberMe: this.formBuilder.control(false)
  });
  formSubmitting = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private firstMfaClient: FirstMfaSetupClient,
    private permissionsApiService: PermissionsClient,
    private permissionsService: PermissionsService,
    private snackbar: SnackbarService,
  ) { }

  onSubmit(): void {
    this.formSubmitting = true;
    this.firstMfaVerificationForm.markAllAsTouched();
    if (this.firstMfaVerificationForm.invalid) return;

    this.firstMfaClient.verifyMfaDevice({
      mfaCode: this.firstMfaVerificationForm.value.mfaCode || '',
      rememberMe: this.firstMfaVerificationForm.value.rememberMe || false,
    }).subscribe({
      next: () => {
        // same as login-form
        this.permissionsApiService.getUserPermissions().subscribe({
          next: (permissions) => {
            this.permissionsService.setPermissions(permissions);
            this.router.navigateByUrl('');
          }
        });
      },
      error: () => {
        // might happen on network errors or if an incorrect MFA code was submitted
        this.snackbar.openSnackbar('There was an error processing your request.', ToastClassEnum.warning);
        this.router.navigateByUrl('/first-mfa-setup');
      }
    });
  }
}