import { RequestModel } from 'src/app/core/clients/generated/client';
import { TableHeader } from 'src/app/shared/helpers/table.helpers';
import { FilterCheckbox, RequestTrackerTypeEnum } from '../models/request-tracker-models';

export const RequestTrackerHeaders: TableHeader<RequestModel>[] = [
  {
    DisplayName: 'Advisor',
    ColumnName: ['advisor'],
    WidthClass: 'min-w-150 w-150',
  },
  {
    DisplayName: 'Client',
    ColumnName: ['client'],
    WidthClass: 'min-w-150 w-150',
    CompareFn: (a: RequestModel, b: RequestModel, order: 1|-1):number => {
      const valA = a.clients ? a.clients.map(c => c.lastName.concat(c.firstName)).join(',') : '';
      const valB = b.clients ? b.clients.map(c => c.lastName.concat(c.firstName)).join(',') : '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  // {
  //   DisplayName: 'Custodian',
  //   ColumnName: ['custodian'],
  //   WidthClass: 'min-w-80',
  // },
  {
    DisplayName: 'Account',
    ColumnName: ['investmentAccountModel', 'custodian'],
    WidthClass: 'min-w-196 w-196',
  },
  {
    DisplayName: 'Type',
    ColumnName: ['category'],
    WidthClass: 'min-w-120 w-120',
  },
  {
    DisplayName: 'Updated On',
    ColumnName: ['statusDate'],
    WidthClass: 'min-w-80 w-80',
  },
  {
    DisplayName: 'Status',
    ColumnName: ['status'],
    WidthClass: 'min-w-80 w-80',
  },
  {
    DisplayName: 'Actions',
    ColumnName: ['actions'],
    WidthClass: 'min-w-80 w-80',
  }
];

export class RequestTrackerFilter {
  Status = '';
  Advisors: FilterCheckbox[] = [];
  Types: FilterCheckbox[] = [
    {
      Name: RequestTrackerTypeEnum.Contribution,
      Value: RequestTrackerTypeEnum.Contribution,
      Checked: false,
    },
    {
      Name: RequestTrackerTypeEnum.Distribution,
      Value: RequestTrackerTypeEnum.Distribution,
      Checked: false,
    },
    {
      Name: RequestTrackerTypeEnum.Reallocation,
      Value: RequestTrackerTypeEnum.Reallocation,
      Checked: false,
    },
    {
      Name: RequestTrackerTypeEnum.CloseAccount,
      Value: RequestTrackerTypeEnum.CloseAccount,
      Checked: false,
    },
    {
      Name: RequestTrackerTypeEnum.PlanningDesk,
      Value: RequestTrackerTypeEnum.PlanningDesk,
      Checked: false,
    },
    {
      Name: RequestTrackerTypeEnum.OrionServiceRequest,
      Value: RequestTrackerTypeEnum.OrionServiceRequest,
      Checked: false,
    },
  ].sort((a, b) => a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0);
}