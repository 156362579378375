import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { fadeIn } from 'src/app/shared/constants/animations';
import { CustomRoute } from 'src/app/shared/models/custom-route.model';
import { Permission } from 'src/app/core/clients/generated/client';
import { MatDialog } from '@angular/material/dialog';
import { OpenSsoAgentSelectModalComponent } from 'src/app/shared/modals/sso-agent-select-modal/sso-agent-select-modal.component';

@Component({
  animations:[fadeIn],
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html'
})
export class MainNavigationComponent implements OnInit{
  navigationItems: CustomRoute[] = this.navigationService.getNavItems();
  subNavigationItems:CustomRoute[] = [];
  showSubNavigation = false;
  perm = Permission;

  constructor
  (
    private navigationService:NavigationService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.navigationService.subNavigationItems$.subscribe(subNavigationItems => {
      this.subNavigationItems = subNavigationItems;
      this.showSubNavigation = subNavigationItems.length > 0;
    });
  }

  openLumaAgentSelectModal(): void {
    this.dialog.open(OpenSsoAgentSelectModalComponent, {
      minHeight: '28rem',
      autoFocus: false,
      data: {BaseUrl: 'api/sso/luma?appearAsCrmAgentId='}
    });
  }

  openOrionAgentSelectModal(destination:string|null): void {
    this.dialog.open(OpenSsoAgentSelectModalComponent, {
      minHeight: '28rem',
      autoFocus: false,
      data: {BaseUrl: 'api/sso/orion/portal/' + destination + '?appearAsCrmAgentId='}
    });
  }
}
