import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExampleModule } from './features/example/example.module';
import { UiExampleModule } from './features/ui-example/ui-example.module';
import { AccountsModule } from './features/accounts/accounts.module';
import { SharedModule } from '../shared/shared.module';
import { MainNavigationComponent } from './main-navigation/main-navigation.component';
import { UserNavigationComponent } from './user-navigation/user-navigation.component';
import { SubNavigationComponent } from './sub-navigation/sub-navigation/sub-navigation.component';
import { PortalComponent } from './portal.component';
import { PortalRoutingModule } from './portal-routing.module';
import { InvestmentModule } from './features/investments/investment.module';
import { FilesMessagesModule } from './features/files-messages/files-messages.module';
import { ResourceCenterModule } from './features/resource-center/resource-center.module';
import { AccountUpdateWorkflowModule } from './features/account-request-workflows/account-update-workflow.module';
import { DashboardModule } from './features/dashboard/dashboard.module';
import { DevelopmentCenterModule } from './features/development-center/development-center.module';
import { RequestTrackerModule } from './features/request-tracker/request-tracker.module';
import { NewPortalOptoutComponent } from './new-portal-optout/new-portal-optout.component';
import { NotificationBannerComponent } from './notification-banner/notification-banner.component';


@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    NewPortalOptoutComponent,
    MainNavigationComponent,
    UserNavigationComponent,
    SubNavigationComponent,
    PortalComponent,
    NotificationBannerComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PortalRoutingModule,
    DashboardModule,
    ExampleModule,
    InvestmentModule,
    UiExampleModule,
    AccountsModule,
    FilesMessagesModule,
    RequestTrackerModule,
    ResourceCenterModule,
    DevelopmentCenterModule,
    AccountUpdateWorkflowModule,
  ]
})
export class PortalModule { }
