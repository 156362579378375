import { Component, Input } from '@angular/core';
import { CustomRoute } from 'src/app/shared/models/custom-route.model';

@Component({
  selector: 'app-sub-navigation',
  templateUrl: './sub-navigation.component.html'
})
export class SubNavigationComponent {
  @Input() subNavigationItems:CustomRoute[]|null = null;
}
