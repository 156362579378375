import { Component, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InvestmentStrategyModalData } from '../../models/investments.models';
import { Permission } from 'src/app/core/clients/generated/client';

@Component({
  selector: 'app-investment-details-modal',
  templateUrl: './investment-details-modal.component.html',
})
export class InvestmentDetailsModalComponent implements AfterViewInit {

  constructor(
    public dialogRef: MatDialogRef<InvestmentDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InvestmentStrategyModalData
  ) { }

  perm = Permission;

  ngAfterViewInit(): void {
    const width = '60rem';
    this.dialogRef.updateSize(width);
  }
}