import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationDetailResponseModel, RegistrationsClient } from 'src/app/core/clients/generated/client';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { FormModel } from 'src/app/shared/helpers/form.helpers';
import { SignupFormModel } from '../../models/auth.models';

@Component({
  selector: 'app-signup-form',
  templateUrl: './signup-form.component.html',
})
export class SignupFormComponent implements OnInit {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private snackbarService: SnackbarService,
    private registrationClient: RegistrationsClient,
  ) { }

  registration?: RegistrationDetailResponseModel;
  failure?: 'complete' | 'expired';
  isDisabled = false;
  isInvalid = false;
  isSubmitting = false;
  signupForm: UntypedFormGroup = this.buildForm();

  ngOnInit(): void {
    const failure = this.route.snapshot.queryParams['failure'];
    if (failure) {
      this.failure = failure as 'complete' | 'expired';
      return;
    }

    this.registrationClient.getSelfRegistration().subscribe({
      next: (response) => this.registration = response,
      error: () => this.failure = 'expired',
    });
  }

  private buildForm(): UntypedFormGroup {
    const form: FormModel<SignupFormModel> = {
      password: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(50)]],
      passwordConfirmation: [null, Validators.required],
    };
    return this.formBuilder.group(form);
  }

  login(): void {
    this.router.navigateByUrl('/login');
  }

  onCompleteSubmit(): void {
    if (this.signupForm.invalid) {
      this.signupForm.markAllAsTouched();
    } else {
      this.isSubmitting = true;
      this.registrationClient.completeRegistration({ password: this.signupForm.controls['password'].value })
        .subscribe({
          next: () => {
            this.snackbarService.openSnackbar('Account created successfully! Please continue to MFA setup.', ToastClassEnum.success, 10, 'top');
            this.router.navigateByUrl('/first-mfa-setup');
          },
          error: () => {
            this.snackbarService.openSnackbar('Error registering account. Please try again.', ToastClassEnum.warning, 7, 'top');
            this.isSubmitting = false;
          },
        });
    }
  }
}
