import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AccountSearchListModel } from 'src/app/core/clients/generated/client';
import { CustomHoldingsComponent } from '../../../account-request-workflows/shared/components/allocation/custom-holdings/custom-holdings.component';
import { PlanningDeskRequestComponent } from '../../../account-request-workflows/planning-desk-request/planning-desk-request.component';
import { ReviewStepComponent } from '../../../account-request-workflows/shared/components/review-step/review-step.component';
import { RequestTypeEnum } from '../../../account-request-workflows/shared/enums/account-update.enum';
import { AllocationStepComponent } from '../../../account-request-workflows/shared/components/allocation/allocation-step.component';
import { DeliveryMethodsComponent } from '../../../account-request-workflows/shared/components/delivery-methods/delivery-methods.component';
import { DeliveryMethodEnum } from '../../../account-request-workflows/shared/enums/delivery-methods.enum';
import { TradeFrequencyEnum } from '../../../account-request-workflows/shared/enums/trade-frequency.enum';
import { DeliveryOptionsFormGroup } from '../../../account-request-workflows/shared/models/delivery-options-form.models';
import { ContributeFundsComponent } from '../../../account-request-workflows/contribution-request/components/contribute-funds/contribute-funds.component';
import { Permission } from 'src/app/core/clients/generated/client';

@Component({
  templateUrl: './ui-landing-page.component.html',
  selector: 'app-ui-landing',
  styleUrls: [ './ui-landing-page.component.scss' ]
})
export class UILandingPageComponent implements OnInit{
  constructor (
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private titleService: Title,
  ) {
    this.deliveryOptionsFormGroup = this.fb.group({
      deliveryMethod: [ DeliveryMethodEnum.ach, [ Validators.required ] ],
      tradeFrequency: [ TradeFrequencyEnum.oneTime, [ Validators.required ] ],
      initiationDate: [ new Date(), [ Validators.required ] ],
      isOvernightCheckDelivery: false,
      journalCashRecipientAccountNumber: [ '', [ Validators.required ] ],
      wireDeliveryOptionId: [ '', [ Validators.required ] ],
    }) as DeliveryOptionsFormGroup;
  }
  deliveryOptionsFormGroup: DeliveryOptionsFormGroup;
  sampleArray = [
    {
      Name: 'Value 1',
      Value: 1
    },
    {
      Name: 'Value 2',
      Value: 2
    },
    {
      Name: 'Value 3',
      Value: 3
    }
  ];
  perm = Permission;

  ngOnInit(): void {
    this.titleService.setTitle(this.activatedRoute.snapshot.data['title'] + ' | Alphastar Portal');
  }

  selectedAccount: AccountSearchListModel = {
    investmentAccountID: 124,
    custodian: 'TD Ameritrade',
    accountNumber: '12345',
    accountValue: 10000,
    cashValue: 5000,
    advisors: [{agentID: 1, crd: '123', firstName: 'Joe', lastName: 'Smith'}],
    clients: 'John Manning',
    investmentAccountType: 'Roth IRA',
    investmentAccountModel: 'Beta Shield Fundamentals'
  };

  openCustomHoldings(): void {
    this.dialog.open<CustomHoldingsComponent, AccountSearchListModel>(CustomHoldingsComponent, {
      data: this.selectedAccount,
      width: '60rem',
      height: '82rem',
      autoFocus: false
    });
  }

  openAllocations(): void {
    this.dialog.open(AllocationStepComponent, {
      width: '60rem',
      height: '82rem',
      autoFocus: false
    });
  }

  openReviewPage(): void {
    this.dialog.open(ReviewStepComponent, {
      width: '60rem',
      height: '82rem',
      autoFocus: false
    });
  }

  openDeliveryMethod(): void {
    this.dialog.open<DeliveryMethodsComponent, { requestType: RequestTypeEnum, form: DeliveryOptionsFormGroup }>(DeliveryMethodsComponent, {
      width: '60rem',
      height: '82rem',
      autoFocus: false,
      data: {
        requestType: RequestTypeEnum.distribution,
        form: this.deliveryOptionsFormGroup,
      }
    });
  }

  openContributeFundsPage(): void {
    this.dialog.open(ContributeFundsComponent, {
      width: '60rem',
      height: '82rem',
      autoFocus: false
    });
  }

  openPlanningDeskRequest(): void {
    this.dialog.open(PlanningDeskRequestComponent, {
      autoFocus: false,
    });
  }
}
