import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { fadeIn } from 'src/app/shared/constants/animations';

@Component({
  animations: [fadeIn],
  selector: 'app-login-page',
  templateUrl: './login-page.component.html'
})
export class LoginPageComponent implements OnInit {
  constructor (
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ){}

  ngOnInit(): void {
    this.titleService.setTitle(this.activatedRoute.snapshot.data['title'] + ' | Alphastar Portal');
  }

}