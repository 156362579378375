import { GenericRequestOption } from '../../shared/models/workflow.models';
import { AnalysisReportTypeEnum } from '../enums/analysis-report-type.enum';
import { InvestmentAccountTypeEnum } from '../enums/investment-account-type.enum';
import { PortfolioAnalysisCategoryEnum } from '../enums/portfolio-analysis-category.enum';
import { RecommendationTypeEnum } from '../enums/recommendation-type.enum';

export const PlanningDeskRequestType: GenericRequestOption[] = [
  {
    name: 'Analysis of current holdings',
    value: PortfolioAnalysisCategoryEnum.analysisOfCurrentHoldings
  },
  {
    name: 'Analysis and proposal',
    value: PortfolioAnalysisCategoryEnum.analysisAndProposal
  }
];

export const RecommendationType: GenericRequestOption[] = [
  {
    name: 'Alphastar’s recommendation',
    value: RecommendationTypeEnum.alphastar,
  },
  {
    name: 'I’ve got a solution in mind',
    value: RecommendationTypeEnum.user,
  },
];

export const AnalysisReportTypeList: GenericRequestOption[] = [
  {
    name: 'Riskalyze',
    value: AnalysisReportTypeEnum.riskalyze
  },
  {
    name: 'Kwanti',
    value: AnalysisReportTypeEnum.kwanti
  },
  {
    name: 'Hidden Levers',
    value: AnalysisReportTypeEnum.hiddenLevers
  },
  {
    name: 'Other (Please specify)',
    value: AnalysisReportTypeEnum.other
  },
];

export const AccountTypeList: GenericRequestOption[] = [
  { value: InvestmentAccountTypeEnum.individual, name: 'Individual' },
  { value: InvestmentAccountTypeEnum.trust, name: 'Trust' },
  { value: InvestmentAccountTypeEnum.estate, name: 'Estate' },
  { value: InvestmentAccountTypeEnum.traditionalIRA, name: 'Traditional IRA' },
  { value: InvestmentAccountTypeEnum.simpleIRA, name: 'Simple IRA' },
  { value: InvestmentAccountTypeEnum.beneficiaryIRS, name: 'Beneficiary IRS' },
  { value: InvestmentAccountTypeEnum.rothIRA, name: 'Roth IRA' },
  { value: InvestmentAccountTypeEnum.SEP_IRA, name: 'SEP IRA' },
  { value: InvestmentAccountTypeEnum.rolloverIRA, name: 'Rollover IRA' },
  { value: InvestmentAccountTypeEnum.k401, name: '401(k)' },
  { value: InvestmentAccountTypeEnum.k401Roth, name: '401(k) Roth' },
  { value: InvestmentAccountTypeEnum.corporation, name: 'Corporation' },
  { value: InvestmentAccountTypeEnum.coverdellESA, name: 'Coverdell ESA' },
  { value: InvestmentAccountTypeEnum.individualTOD, name: 'Individual TOD' },
  { value: InvestmentAccountTypeEnum.limitedLiabilityCompany, name: 'Limited Liability Company' },
  { value: InvestmentAccountTypeEnum.profitSharingPlan, name: 'Profit Sharing Plan' },
  { value: InvestmentAccountTypeEnum.retirementTrust, name: 'Retirement Trust' },
  { value: InvestmentAccountTypeEnum.UGMA_UTMA, name: 'UGMA/UTMA' },
  { value: InvestmentAccountTypeEnum.jointTOD, name: 'Joint TOD' },
  { value: InvestmentAccountTypeEnum.beneficiaryRothIRA, name: 'Beneficiary Roth IRA' },
  { value: InvestmentAccountTypeEnum.JTWROS, name: 'JTWROS' },
  { value: InvestmentAccountTypeEnum.taxExempt, name: 'Tax Exempt' },
  { value: InvestmentAccountTypeEnum.variableAnnuity, name: 'Variable Annuity' },
  { value: InvestmentAccountTypeEnum.jointTennantsInCommon, name: 'Joint Tennants in Common' },
  { value: InvestmentAccountTypeEnum.tenantsByTheEntirety, name: 'Tenants by the Entirety' },
  { value: InvestmentAccountTypeEnum.JTWROS_TOD, name: 'JTWROS TOD' },
  { value: InvestmentAccountTypeEnum.guardianship, name: 'Guardianship' },
  { value: InvestmentAccountTypeEnum.guardianshipTOD, name: 'Guardianship TOD' },
  { value: InvestmentAccountTypeEnum.a401, name: '401(a)' },
  { value: InvestmentAccountTypeEnum.b401, name: '401(b)' },
  { value: InvestmentAccountTypeEnum.k401Plan, name: '401(k) Plan' },
  { value: InvestmentAccountTypeEnum.financialPlan, name: 'Financial Plan' },
  { value: InvestmentAccountTypeEnum.minorIRA, name: 'Minor IRA' },
  { value: InvestmentAccountTypeEnum.minorRothIRA, name: 'Minor Roth IRA' },
  { value: InvestmentAccountTypeEnum.conservatorship, name: 'Conservatorship' },
  { value: InvestmentAccountTypeEnum.jointCommunityProperty, name: 'Joint Community Property' },
  { value: InvestmentAccountTypeEnum.definedBenefitPlan, name: 'Defined Benefit Plan' },
  { value: InvestmentAccountTypeEnum.partnership, name: 'Partnership' },
  { value: InvestmentAccountTypeEnum.k401Plan321, name: '401(k) Plan 3(21)' },
  { value: InvestmentAccountTypeEnum.k401Plan338, name: '401(k) Plan 3(38)' },
  { value: InvestmentAccountTypeEnum.capitalAppreciation, name: 'Capital Appreciation' },
  { value: InvestmentAccountTypeEnum.fixedIndexAnnuity, name: 'Fixed Index Annuity' }
];