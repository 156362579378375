import { Directive, Output, EventEmitter, HostListener, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';

@Directive({
  selector: '[appDebouncedModelChanged]',
})
export class DebounceModelChangeDirective implements OnInit, OnDestroy {
  @Output() appDebouncedModelChanged = new EventEmitter<string>();
  private searchTermSubject = new Subject<string>();
  private subscription: Subscription = new Subscription();
  constructor(private element: ElementRef<HTMLInputElement>) { }

  ngOnInit(): void {
    this.subscription = this.searchTermSubject.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.appDebouncedModelChanged.emit(searchTerm);
      });
  }

  @HostListener('ngModelChange') onModelChange(): void {
    this.searchTermSubject.next(this.element.nativeElement.value);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}