import { DevelopmentCenterWistiaSectionEnum, DevelopmentCenterWistiaSectionMap } from '../models/development-center.models';


export const DevelopmentCenterWistiaSectionList: DevelopmentCenterWistiaSectionMap[] = [
  {
    Name: 'Next Level',
    SectionId: DevelopmentCenterWistiaSectionEnum.NextLevelTop,
  },
  {
    Name: 'Topical Videos',
    SectionId: DevelopmentCenterWistiaSectionEnum.NextLevelTopical,
  },
  {
    Name: 'Previous Events',
    SectionId: DevelopmentCenterWistiaSectionEnum.NextLevelPreviousEvents,
  },
  {
    Name: 'Digital Resources',
    SectionId: DevelopmentCenterWistiaSectionEnum.NextLevelDigitalResources,
  },
  {
    Name: 'AlphaStart™',
    SectionId: DevelopmentCenterWistiaSectionEnum.AlphaStartTop,
  },
  {
    Name: 'AlphaStart™ Resources',
    SectionId: DevelopmentCenterWistiaSectionEnum.AlphaStartViewMore,
  },
  {
    Name: 'Webinars',
    SectionId: DevelopmentCenterWistiaSectionEnum.VirtualLearningTop,
  },
  {
    Name: 'Webinar Resources',
    SectionId: DevelopmentCenterWistiaSectionEnum.VirtualLearningLearnMore,
  },
  {
    Name: 'FinishSTRONG™',
    SectionId: DevelopmentCenterWistiaSectionEnum.FinishStrongTop,
  },
  {
    Name: 'FinishSTRONG™ Resources',
    SectionId: DevelopmentCenterWistiaSectionEnum.FinishStrongLearnMore,
  },
  {
    Name: 'Best Practices',
    SectionId: DevelopmentCenterWistiaSectionEnum.BestPracticesTop,
  },
  {
    Name: 'Best Practices Resources',
    SectionId: DevelopmentCenterWistiaSectionEnum.BestPracticesLearnMore,
  },
  {
    Name: 'Recent Communications',
    SectionId: DevelopmentCenterWistiaSectionEnum.RecentCommunications,
  },
  {
    Name: 'Coffee with Compliance',
    SectionId: DevelopmentCenterWistiaSectionEnum.CoffeeWithComplianceTop,
  },
  {
    Name: 'Coffee with Compliance Resources',
    SectionId: DevelopmentCenterWistiaSectionEnum.CoffeeWithComplianceLearnMore,
  },
  {
    Name: 'Sales Summit 2024',
    SectionId: DevelopmentCenterWistiaSectionEnum.SalesSummit2024Top,
  },
  {
    Name: 'Sales Summit 2024 Resources',
    SectionId: DevelopmentCenterWistiaSectionEnum.SalesSummit2024LearnMore,
  },
];