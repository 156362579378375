import { Component, Input } from '@angular/core';
import { AllocationCustomHoldingsFormGroup } from '../../../../models/allocation-form.models';

@Component({
  selector: 'app-custom-holdings-notes',
  templateUrl: './custom-holdings-notes.component.html',
})
export class CustomHoldingsNotesComponent  {
  @Input() parentForm?: AllocationCustomHoldingsFormGroup;
}
