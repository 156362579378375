import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, Subject } from 'rxjs';
import { AcmMediaAsset, AcmMediaProject, WistiaClient } from 'src/app/core/clients/generated/client';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { fadeIn } from 'src/app/shared/constants/animations';
import { WistiaProjectsEnum } from 'src/app/shared/enums/wistia.enum';
import { strictSearch } from 'src/app/shared/helpers/search.helpers';
import { ResourceCenterModel, ResourceCenterViewMoreModel } from '../../models/resource-center.models';
import { ResourceCenterService } from '../../services/resource-center.service';

@Component({
  animations: [fadeIn],
  selector: 'app-resource-center-page',
  templateUrl: './resource-center-page.component.html'
})
export class ResourceCenterPageComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private snackbarService: SnackbarService,
    private titleService: Title,
    private wistiaApiService: WistiaClient,
    private _resourceCenterService: ResourceCenterService,
  ) { }
  isLoading = false;
  isSearching = false;
  resourcesFeatured: ResourceCenterModel[] = [];
  allResources: ResourceCenterModel[] = [];
  resourcesViewMore: ResourceCenterViewMoreModel[] = [];
  searchResults: AcmMediaAsset[] = [];
  searchTerm = '';
  searchTermChange = new Subject<string>();
  wistiaProject?: AcmMediaProject;

  ngOnInit(): void {
    this.isLoading = true;
    this.allResources = this._resourceCenterService.resourceCenterResources;
    this.resourcesFeatured = this._resourceCenterService.resourceCenterResources.filter(resource => resource.IsFeatured);
    this.resourcesViewMore = this._resourceCenterService.resourceCenterResources.map(resource => resource.ViewMore) ?? {};

    this.titleService.setTitle(this.activatedRoute.snapshot.data['title'] + ' | Alphastar Portal');
    this.wistiaApiService.getProjectAssets(WistiaProjectsEnum.ResourceCenter)
      .subscribe({
        next: result => {
          this.wistiaProject = result;
          this.allocateResources();
        }, error: () => {
          this.snackbarService.openSnackbar('Unable to load resources', ToastClassEnum.warning);
        }
      })
      .add(() => this.isLoading = false);

    this.searchTermChange.pipe(
      debounceTime(400))
      .subscribe(value => {
        this.searchForAssets(value);
      });
  }

  searchTermChanged(term: string): void {
    this.isSearching = true;
    this.searchTermChange.next(term);
  }

  searchForAssets(term: string): void {
    let results: AcmMediaAsset[] = [];
    if (this.searchTerm.length) {
      const flatAssets = this.allResources.map(a => (a.Assets || []).concat(a.ViewMore?.Assets || []).concat(a.ViewMore?.SubSections?.map(a => a.Assets).flat() || [])).flat();
      results = strictSearch(flatAssets, term, ['name']);
    }
    // enforcing uniqueness for 'name' property. reasoning is bc 'view more' sections contain all assets from their corresponding parent section - don't want same files returned twice here
    this.searchResults = [...new Map(results.map(item => [item['name'], item])).values()].sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
    this.isSearching = false;
  }

  // pair up each set of assets with correct content section based on sectionId from Wistia Api
  allocateResources(): void {
    this.allResources.forEach(resource => {
      // main assets
      resource.Assets = this.wistiaProject?.content.find(content => content.sectionId === resource.Id)?.assets || [];
      // view more assets
      if (resource.ViewMore) {
        if (resource.ViewMore.Id) resource.ViewMore.Assets = this.wistiaProject?.content.find(content => content.sectionId === resource.ViewMore?.Id)?.assets || [];
        if (resource.ViewMore.SubSections?.length) {
          resource.ViewMore.SubSections.forEach(subSection => {
            // sub-section assets
            subSection.Assets = this.wistiaProject?.content.find(content => content.sectionId === subSection.Id)?.assets || [];
          });
        }
      }
    });
  }

  navigate(resource: ResourceCenterModel): void {
    if (resource.ViewMore && resource.ViewMore.HtmlId) {
      const elem = document.getElementById(resource.ViewMore.HtmlId);
      if (!elem) return;

      resource.ViewMore.Expanded = true;

      setTimeout(() => {
        elem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }, 100);
    }
  }
}