import { Component, Input, OnInit } from '@angular/core';
import { DistributionRequestComponent } from '../../../distribution-request/distribution-request.component';
import { MatDialogRef } from '@angular/material/dialog';
import { RequestTypeEnum } from '../../enums/account-update.enum';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
})
export class CompleteComponent implements OnInit  {

  constructor(
    private dialogRef: MatDialogRef<DistributionRequestComponent>
  ) { }

  @Input() complete?:boolean;
  @Input() requestType?: RequestTypeEnum;
  requestTypeEnum = RequestTypeEnum;
  requestTypeName = '';
  closeRequest(): void {
    this.dialogRef.close(false);
  }

  ngOnInit(): void {
    this.requestTypeName = this.requestType === this.requestTypeEnum.contribution ? 'contribution' : this.requestType === this.requestTypeEnum.distribution ? 'distribution' : this.requestType === this.requestTypeEnum.reallocation ? 'model allocation / trade' : this.requestType === this.requestTypeEnum.closeAccount ? 'close account': this.requestType === this.requestTypeEnum.planningDesk ? 'planning desk' : '';
  }
}