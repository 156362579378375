import { TaskFilesListModel } from 'src/app/core/clients/generated/client';
import { TableHeader } from 'src/app/shared/helpers/table.helpers';

export const UploadTableHeaders: TableHeader<TaskFilesListModel>[] = [
  {
    DisplayName: 'Advisor',
    ColumnName: ['advisors'],
    WidthClass: 'min-w-144 w-144',
    CompareFn: (a: TaskFilesListModel, b: TaskFilesListModel, order: 1|-1):number => {
      const valA = a.advisors ? a.advisors.map(a => a.firstName?.toLowerCase().concat(a.lastName?.toLowerCase())).join(',') : '';
      const valB = b.advisors ? b.advisors.map(a => a.firstName?.toLowerCase().concat(a.lastName?.toLowerCase())).join(',') : '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Client',
    ColumnName: ['clients'],
    WidthClass: 'min-w-144 w-144',
    CompareFn: (a: TaskFilesListModel, b: TaskFilesListModel, order: 1|-1):number => {
      const valA = a.clients ? a.clients.map(c => c.lastName?.toLowerCase().concat(c.firstName?.toLowerCase())).join(',') : '';
      const valB = b.clients ? b.clients.map(c => c.lastName?.toLowerCase().concat(c.firstName?.toLowerCase())).join(',') : '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Account',
    ColumnName: ['investmentAccountModel', 'custodian', 'accountNumber'],
    WidthClass: 'min-w-144 w-144',
    CompareFn: (a: TaskFilesListModel, b: TaskFilesListModel, order: 1|-1):number => {
      const valA = (a.investmentAccountModel?.toLowerCase() ?? 'no model').concat(a.custodian?.toLowerCase() ?? '').concat(a.accountNumber?.toLowerCase() ?? '');
      const valB = (b.investmentAccountModel?.toLowerCase() ?? 'no model').concat(b.custodian?.toLowerCase() ?? '').concat(b.accountNumber?.toLowerCase() ?? '');
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Message',
    ColumnName: ['requestType', 'taskDescription'],
    WidthClass: 'min-w-144 w-144',
    CompareFn: (a: TaskFilesListModel, b: TaskFilesListModel, order: 1|-1):number => {
      const valA = (a.requestType?.toLowerCase() ?? '').concat(a.taskDescription?.toLowerCase() ?? '');
      const valB = (b.requestType?.toLowerCase() ?? '').concat(b.taskDescription?.toLowerCase() ?? '');
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Uploaded On',
    ColumnName: ['createdOn'],
    WidthClass: 'max-w-60 w-60',
    CompareFn: (a: TaskFilesListModel, b: TaskFilesListModel, order: 1|-1):number => {
      const valA = a.createdOn;
      const valB = b.createdOn;
      if (valA === valB) return 0;
      if (valA === null) return -1 * order;
      if (valB === null) return 1 * order;
      return (valA > valB ? 1 : -1) * order;
    },
  },
];
