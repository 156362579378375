import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-review-row-entry',
  templateUrl: './review-row-entry.component.html',
  styleUrls: [ './review-row-entry.component.scss' ]
})
export class ReviewRowEntryComponent {
  @Input() dataName?: string;
  @Input() dataValue?: string | number | null;
  @Input() config?: {
    dataNameClass?: string;
    dataValueClass?: string;
  };
}
