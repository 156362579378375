import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';
import { AdditionalOfferings, InvestmentStrategies, EvolvInvestmentStrategies } from 'src/app/portal/features/investments/constants/investments.constant';
import { SidenavConfig } from 'src/app/shared/models/generic.models';
import { InvestmentStrategyFilters, InvestmentStrategyModel, InvestmentModelCheckboxFilter, AdditionalOfferingModel, TagEnum, RiskToleranceEnum, ModelMinimumEnum, ManagerFeeEnum, MoneyManagerEnum, InvestmentStrategyModalData } from 'src/app/portal/features/investments/models/investments.models';
import { hasAll } from 'src/app/shared/helpers/search.helpers';
import { customBreakpointsEnum } from 'src/app/shared/enums/custom-breakpoints.enum';
import { WistiaProjectsEnum } from 'src/app/shared/enums/wistia.enum';
import { InvestmentDetailsModalComponent } from '../modals/investment-details-modal/investment-details-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AcmMediaProject, WistiaClient } from 'src/app/core/clients/generated/client';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { fadeIn } from 'src/app/shared/constants/animations';
import { Permission } from 'src/app/core/clients/generated/client';
import { PermissionsService } from 'src/app/core/auth/permissions.service';

// TODO:
// Global sort function to use instead of .sort() multiple times in this file [RC]
@Component({
  animations: [fadeIn],
  selector: 'app-investment-list',
  templateUrl: './investments-page.component.html',
})
export class InvestmentsPageComponent implements OnInit, OnDestroy {

  perm = Permission;

  constructor(
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private wistiaApiService: WistiaClient,
    private titleService: Title,
    private _permissionService: PermissionsService
  ) {
    this.sidenavConfig = {
      Mode: 'side',
      IsOpened: true
    };
  }
  private searchTermSubject = new Subject<string>();
  moneyManagerFilters: InvestmentModelCheckboxFilter[] = [
    {
      Name: MoneyManagerEnum.Alphastar,
      Checked: false,
    },
    {
      Name: MoneyManagerEnum.Inspire,
      Checked: false,
    },
    {
      Name: MoneyManagerEnum.Invesco,
      Checked: false,
    },
    {
      Name: MoneyManagerEnum.Navellier,
      Checked: false,
    },
    {
      Name: MoneyManagerEnum.Russell,
      Checked: false,
    },
    {
      Name: MoneyManagerEnum.Twin,
      Checked: false,
    },
    {
      Name: MoneyManagerEnum.VanHulzen,
      Checked: false,
    },
    {
      Name: MoneyManagerEnum.Vanguard,
      Checked: false,
    },
  ].sort((a, b) => a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0);
  managerFeeFilters: InvestmentModelCheckboxFilter[] = [
    {
      Name: ManagerFeeEnum.Zero,
      Checked: false,
    },
    {
      Name: ManagerFeeEnum.Five,
      Checked: false,
    },
    {
      Name: ManagerFeeEnum.Ten,
      Checked: false,
    },
    {
      Name: ManagerFeeEnum.TwentyTwo,
      Checked: false,
    },
    {
      Name: ManagerFeeEnum.TwentyFive,
      Checked: false,
    },
    {
      Name: ManagerFeeEnum.Thirty,
      Checked: false,
    },
    {
      Name: ManagerFeeEnum.FortyThree,
      Checked: false,
    },
  ].sort((a, b) => a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0);
  modelMinimumFilters: InvestmentModelCheckboxFilter[] = [
    {
      Name: ModelMinimumEnum.FiveHundred,
      Checked: false,
    },
    {
      Name: ModelMinimumEnum.FiveThousand,
      Checked: false,
    },
    {
      Name: ModelMinimumEnum.TenThousand,
      Checked: false,
    },
    {
      Name: ModelMinimumEnum.FifteenThousand,
      Checked: false,
    },
    {
      Name: ModelMinimumEnum.TwentyFiveThousand,
      Checked: false,
    },
    {
      Name: ModelMinimumEnum.FiftyThousand,
      Checked: false,
    },
    {
      Name: ModelMinimumEnum.OneHundredThousand,
      Checked: false,
    },
  ].sort((a, b) => a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0);
  riskToleranceFilters: InvestmentModelCheckboxFilter[] = [
    {
      Name: RiskToleranceEnum.Conservative,
      Checked: false,
    },
    {
      Name: RiskToleranceEnum.ModeratelyConservative,
      Checked: false,
    },
    {
      Name: RiskToleranceEnum.Moderate,
      Checked: false,
    },
    {
      Name: RiskToleranceEnum.ModeratelyAggressive,
      Checked: false,
    },
    {
      Name: RiskToleranceEnum.Aggressive,
      Checked: false,
    },
  ];
  tagFilters: InvestmentModelCheckboxFilter[] = [
    {
      Name: TagEnum.Growth,
      Checked: false,
    },
    {
      Name: TagEnum.Preservation,
      Checked: false,
    },
    {
      Name: TagEnum.Income,
      Checked: false,
    },
    {
      Name: TagEnum.TaxEfficient,
      Checked: false,
    },
    {
      Name: TagEnum.RiskMitigation,
      Checked: false,
    },
  ].sort((a, b) => a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0);
  enforceFilters: InvestmentStrategyFilters = {
    RiskTolerance: this.riskToleranceFilters.filter(filter => filter.Checked).map(filter => filter.Name as RiskToleranceEnum),
    MoneyManager: this.moneyManagerFilters.filter(filter => filter.Checked).map(filter => filter.Name as MoneyManagerEnum),
    ManagerFee: this.managerFeeFilters.filter(filter => filter.Checked).map(filter => filter.Name as ManagerFeeEnum),
    ModelMinimum: this.modelMinimumFilters.filter(filter => filter.Checked).map(filter => filter.Name as ModelMinimumEnum),
    Tags: this.tagFilters.filter(filter => filter.Checked).map(filter => filter.Name as TagEnum),
  };
  //investmentStrategies: InvestmentStrategyModel[] = EvolveInvestmentStrategies;
  investmentStrategies = this._permissionService.hasOnePermission(Permission.ViewEvolvExperience) ? EvolvInvestmentStrategies : InvestmentStrategies;
  filteredInvestmentStrategies: InvestmentStrategyModel[] = this.investmentStrategies;
  additionalOfferings: AdditionalOfferingModel[] = AdditionalOfferings;
  isLoading = false;
  searchTerm = '';
  sidenavConfig!: SidenavConfig;
  wistiaProject?: AcmMediaProject;
  private breakpointSubscription?: Subscription;

  get hasActiveFilters(): boolean {
    return this.enforceFilters.RiskTolerance.length
      || this.enforceFilters.MoneyManager.length
      || this.enforceFilters.ManagerFee.length
      || this.enforceFilters.ModelMinimum.length
      || this.enforceFilters.Tags.length
      ? true : false;
  }

  ngOnInit(): void {

    this.isLoading = false;
    this.titleService.setTitle(this.activatedRoute.snapshot.data['title'] + ' | Alphastar Portal');
    this.breakpointSubscription = this.breakpointObserver.observe([
      customBreakpointsEnum.lg
    ])
      .subscribe(result => {
        if (result.matches) {
          this.sidenavConfig.IsOpened = true;
          this.sidenavConfig.Mode = 'side';
        } else {
          this.sidenavConfig.IsOpened = false;
          this.sidenavConfig.Mode = 'over';
        }
      });

    this.searchTermSubject.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.searchTerm = searchTerm;
        this.updateFilteredList();
      });

    this.wistiaApiService.getProjectAssets(WistiaProjectsEnum.Investments)
      .subscribe({
        next: result => {
          this.wistiaProject = result;
        }
      })
      .add(() => this.isLoading = false);

    this.updateFilteredList();
  }

  ngOnDestroy(): void {
    this.breakpointSubscription?.unsubscribe();
    this.searchTermSubject.unsubscribe();
  }

  // Clear all filters
  clearFilters(): void {
    this.moneyManagerFilters.forEach(filter => filter.Checked = false);
    this.managerFeeFilters.forEach(filter => filter.Checked = false);
    this.modelMinimumFilters.forEach(filter => filter.Checked = false);
    this.riskToleranceFilters.forEach(filter => filter.Checked = false);
    this.tagFilters.forEach(filter => filter.Checked = false);
    this.enforceFilters = {
      RiskTolerance: [],
      MoneyManager: [],
      ManagerFee: [],
      ModelMinimum: [],
      Tags: [],
    };
    this.searchTerm = '';
    this.updateFilteredList();
  }

  openInvestmentDetailsModal(model: AdditionalOfferingModel): void {
    this.dialog.open<InvestmentDetailsModalComponent, InvestmentStrategyModalData>(InvestmentDetailsModalComponent, {
      maxHeight: '90rem',
      data: {
        Name: model.Name,
        LogoUrls: model.LogoUrls,
        IsBetaShield: false,
        Description: model.Description + ' ' + model.AdditionalDescription,
        Assets: this.wistiaProject?.content.find(filter => filter.sectionId === model.Id)?.assets || [],
        Error: this.wistiaProject ? false : true
      }
    });
  }

  // Update the current set of filters that should be active
  updateActiveFilters(checkboxFilter: InvestmentModelCheckboxFilter, activeFilters: (string | number)[]): void {
    if (checkboxFilter.Checked) activeFilters.push(checkboxFilter.Name);
    else activeFilters.splice(activeFilters.indexOf(checkboxFilter.Name), 1);
    this.updateFilteredList();
  }

  // Final filtering - PER FILTER SECTION: either no filters are selected (show all) or *some* are selected, so must include
  updateFilteredList(): void {
    const filterStrategy = (strategy: InvestmentStrategyModel): boolean => {
      return (!this.enforceFilters.RiskTolerance.length || this.enforceFilters.RiskTolerance.includes(strategy.RiskTolerance))
        && (!this.enforceFilters.MoneyManager.length || this.enforceFilters.MoneyManager.includes(strategy.MoneyManager))
        && (!this.enforceFilters.ManagerFee.length || this.enforceFilters.ManagerFee.includes(strategy.ManagerFee))
        && (!this.enforceFilters.ModelMinimum.length || this.enforceFilters.ModelMinimum.includes(strategy.ModelMinimum))
        && (!this.enforceFilters.Tags.length || this.enforceFilters.Tags.some(tag => strategy.Tags.includes(tag)));
    };

    this.filteredInvestmentStrategies = this.investmentStrategies
      .filter(strategy => filterStrategy(strategy) && hasAll(strategy, this.searchTerm, ['Name', 'RiskTolerance', 'MoneyManager', 'ManagerFee', 'ModelMinimum', 'Tags']))
      .sort((a, b) => a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0);
  }

  searchTermChanged(searchTerm: string): void {
    this.searchTermSubject.next(searchTerm);
  }

  // Update tag filters
  toggleTag(tag: InvestmentModelCheckboxFilter): void {
    tag.Checked = !tag.Checked;
    this.updateActiveFilters(tag, this.enforceFilters.Tags);
  }
}