export class Table<T> {
  Header: TableHeader<T>[];
  Body: T[] = [];
  OrderByColumn: TableHeader<T>;
  OrderDirection: OrderDirection;

  constructor(public Headers: TableHeader<T>[], public DefaultOrderedByColumnIndex: number = 0, public DefaultOrderedByDirection: OrderDirection = 'asc') {
    this.Header = Headers;
    this.OrderByColumn = Headers[DefaultOrderedByColumnIndex];
    this.OrderDirection = DefaultOrderedByDirection;
  }

  orderBody(): T[] {
    const order = this.OrderDirection === 'asc' ? 1 : -1;
    this.Body = this.Body.sort((a: T, b: T) => this.OrderByColumn.CompareFn ? this.OrderByColumn.CompareFn(a, b, order)
      : this.defaultCompare(this.OrderByColumn.ColumnName[0] as keyof T, a, b, order));
    return this.Body;
  }

  defaultCompare(property: keyof T, a: T, b: T, order: 1|-1): number {
    const valA = a[property] ?? '';
    const valB = b[property] ?? '';
    return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
  }

  setOrderByColumn(column: TableHeader<T>) : void {
    this.OrderByColumn = column;
    this.orderBody();
  }

  setOrderDirection(direction: OrderDirection): void {
    this.OrderDirection = direction;
    this.orderBody();
  }

  setBody(rows: T[]):void {
    this.Body = rows;
    this.orderBody();
  }

  switchOrderDirection(): void {
    if (this.OrderDirection === 'asc') {
      this.OrderDirection = 'desc';
    } else {
      this.OrderDirection = 'asc';
    }
    this.orderBody();
  }

  orderBy(column: TableHeader<T>) : void {
    if (this.OrderByColumn === column) {
      this.switchOrderDirection();
    } else {
      this.setOrderByColumn(column);
    }
    this.refreshTable();
  }

  refreshTable(): void{
    this.Body = [...this.Body];
  }
}

export type OrderDirection = 'asc'|'desc';

export interface TableHeader<T>{
  DisplayName: string;
  ColumnName: string[];
  WidthClass: string;
  CompareFn?: (a: T, b: T, order: 1 | -1) => number;
}