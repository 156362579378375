import { Injectable } from '@angular/core';
import { UserPermissionModel } from '../clients/generated/client';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor() { }

  clearPermissions():void{
    localStorage.removeItem('Auth.Permissions');
  }

  setPermissions(permissions: UserPermissionModel[]): void {
    localStorage.setItem('Auth.Permissions', JSON.stringify(permissions));
  }

  private getPermissions(): UserPermissionModel[] {
    return JSON.parse(localStorage.getItem('Auth.Permissions') || '[]') as UserPermissionModel[];
  }

  hasOnePermission(permissions : string|string[]): boolean {
    permissions = Array.isArray(permissions) ? permissions : [permissions];
    const userPermissions = this.getPermissions();
    let hasMatch = false;
    for (const permission of permissions) {
      if (userPermissions.find(up => up.permissionName.toLowerCase() === permission.toLowerCase())) {
        hasMatch = true;
        break;
      }
    }
    return hasMatch;
  }

  hasAllPermissions(permissions: string[]): boolean{
    const userPermissions = this.getPermissions();
    let hasMatch = true;
    for (const permission of permissions) {
      if (!userPermissions.find(up => up.permissionName.toLowerCase() === permission.toLowerCase())) {
        hasMatch = false;
        break;
      }
    }
    return hasMatch;
  }
}
