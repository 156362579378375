import { AcmMediaAsset } from 'src/app/core/clients/generated/client';

export interface ResourceCenterModel {
  Id: string,
  Name: SectionEnum,
  BackgroundUrl: string | null,
  Sso?: {
    Name: string,
    Url: string,
  },
  ViewMore: ResourceCenterViewMoreModel,
  Assets?: AcmMediaAsset[],
  Resolve?:() => boolean,
  IsFeatured: boolean
}

export interface ResourceCenterViewMoreModel {
  Id?: string,
  HtmlId?: HtmlEnum,
  Name?: SectionEnum,
  Assets?: AcmMediaAsset[],
  SubSections?: {
    Id: string,
    Name: string,
    Assets: AcmMediaAsset[]
  }[]
  Expanded?: boolean
}

export enum SectionEnum {
  ComplianceResources = 'Compliance Resources',
  AlphastarSpecificPaperwork = 'Alphastar Specific Paperwork',
  EvolvResources = 'Evolv Resources',
  TDAmeritradeForms = 'TD Ameritrade Forms',
  CharlesSchwabForms = 'Charles Schwab Forms',
  Erisa401kPlanForms = 'Erisa and 401(k) Plan Forms',
  InvestmentResources = 'Investment Resources',
  OrionForms = 'Orion Forms',
}

export enum HtmlEnum {
  ComplianceResources = 'viewMoreComplianceResources',
  AlphastarSpecificPaperwork = 'viewMoreAlphastarSpecificPaperwork',
  EvolvResources = 'viewMoreEvolvResources',
  TDAmeritradeForms = 'viewMoreTDAmeritradeForms',
  CharlesSchwabForms = 'viewMoreCharlesSchwabForms',
  Erisa401kPlanForms = 'viewMoreErisa401kPlanForms',
  InvestmentResources = 'viewMoreInvestmentResources',
  OrionForms = 'viewMoreOrionForms',
}