// This gets the offset amount of the entire virtual scroll viewport from the top in pixels.

import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

// We use this to manually sticky the header back to the top position in the HTML since virtual scroll messes with sticky headers
export const virtualScrolloffsetTranslate = (viewPort?: CdkVirtualScrollViewport): string => {
  if (!viewPort || !viewPort['_renderedContentOffset'] as boolean) {
    return '-0px';
  }
  const offset = viewPort['_renderedContentOffset'] as number;
  return `-${offset}px`;
};