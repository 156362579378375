import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TaskFilesListModel } from 'src/app/core/clients/generated/client';
import { fadeIn } from 'src/app/shared/constants/animations';
import { Table } from 'src/app/shared/helpers/table.helpers';
import { virtualScrolloffsetTranslate } from 'src/app/shared/helpers/virtual-scroll-offset-translate.helper';
import { FileMessageDetailsModalComponent } from '../../modals/file-message-details-modal/file-message-details-modal.component';
import { UploadTableHeaders } from '../../models/files-messages.table';

@Component({
  animations: [ fadeIn ],
  selector: 'app-files-messages-list',
  templateUrl: './files-messages-list.component.html',
})
export class FilesMessagesListComponent implements OnChanges {
  @ViewChild(CdkVirtualScrollViewport, { static: false }) public viewPort?: CdkVirtualScrollViewport;
  @Input() uploads: TaskFilesListModel[] = [];
  isLoading = true;
  table: Table<TaskFilesListModel> = new Table<TaskFilesListModel>(UploadTableHeaders, 4, 'desc');

  constructor (
    private dialog: MatDialog,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes['uploads']?.isFirstChange()) {
      this.table.setBody(this.uploads);
      this.isLoading = false;
    }
  }

  // This get the offset amount of the entire virtual scroll viewport from the top in pixel.
  // We use this to manually sticky the header back to the top position in the HTML since virtual scroll messes with sticky headers
  public get offsetTranslate(): string {
    return virtualScrolloffsetTranslate(this.viewPort);
  }

  openFileMessageDetailsModal(model: TaskFilesListModel): void {
    this.dialog.open<FileMessageDetailsModalComponent, TaskFilesListModel>(FileMessageDetailsModalComponent, {
      maxHeight: '90rem',
      data: model
    });
  }
}
