import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TaskFilesListModel } from 'src/app/core/clients/generated/client';
import { fadeIn } from 'src/app/shared/constants/animations';

@Component({
  animations: [fadeIn],
  selector: 'app-file-message-details-modal',
  templateUrl: './file-message-details-modal.component.html',
})
export class FileMessageDetailsModalComponent implements AfterViewInit {

  constructor(
    public dialogRef: MatDialogRef<FileMessageDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TaskFilesListModel,
  ) { }

  ngAfterViewInit(): void {
    const width = '50rem';
    this.dialogRef.updateSize(width);
  }
}
