import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationModalData } from './modal-data.models';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
})
export class ConfirmationModalComponent {

  constructor (
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationModalData
  ) { }

  /**
   * Example use in another component. Make sure you specify the type of data being passed in (line 37) to help type checking:
   *
   * import { MatDialog } from '@angular/material/dialog';
   * import { ConfirmationComponent } from '../../modals/confirmation/confirmation.component'
   * ...
   * constructor(private dialog: MatDialog, ...)
   * ...
   * // this.dialog.open<SpecifyComponentType: ConfirmationComponent, SpecifyDataType: ConfirmationData, SpecifyAfterClosedResultType: boolean>
   * openConfirmationModal(): void {
   *   const ref = this.dialog.open<ConfirmationComponent, ConfirmationData, boolean>(ConfirmationComponent, {
   *     data : {
   *       title: "Confirmation title", //optional
   *       message: "Are sure you want to do the thing?",
   *       confirmationButtonText: "Do the thing", //optional
   *       isWarning: true, //optional
   *       hideCancelButton: true, //optional
   *     }
   *   });
   *
   *   ref.afterClosed().subscribe(result => {
   *     if (result) {
   *       // The user has confirmed, do the the thing
   *     } else {
   *       // The user did not confirm, do something else, or nothing at all
   *     }
   *   });
   * }
   */
}
