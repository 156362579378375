import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountModel } from 'src/app/core/clients/generated/client';
import { InvestmentRequestFormService } from '../../../services/investment-request-form.service';

@Component({
  selector: 'app-account-request-modal-header',
  templateUrl: './account-request-modal-header.component.html',
})
export class AccountRequestModalHeaderComponent implements OnInit {
  @Input() formService?:InvestmentRequestFormService;
  @Input() account?:AccountModel;
  @Input() title?:string;
  @Output() closeRequested = new EventEmitter<boolean>();
  clients?:string;

  constructor() { }

  ngOnInit(): void {
    this.clients = this.account?.clients.map(c => `${c.firstName} ${c.lastName}`).join(', ');
  }

  closeModal(): void {
    this.closeRequested.next(true);
  }

}
