export enum RequestTrackerTypeEnum {
  Contribution = 'Contribution',
  Distribution = 'Distribution',
  Reallocation = 'Re-Allocation',
  CloseAccount = 'Close Account',
  PlanningDesk = 'Planning Desk',
  OrionServiceRequest = 'Orion Service Request',
  Clients = 'Clients',
  Sleeves = 'Allocations',
  Trades = 'Trades',
  Accounts = 'Accounts',
  GrossDistributionAmount = 'Gross Distribution Amount',
  GrossContributionAmount = 'Gross Contribution Amount',
}

export interface FilterCheckbox {
  Name: string | number;
  Value: string | number;
  Checked: boolean;
}