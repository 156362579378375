import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordResetClient } from 'src/app/core/clients/generated/client';

@Component({
  selector: 'app-password-reset-form',
  templateUrl: './password-reset-form.component.html',
})
export class PasswordResetFormComponent {
  @Output() formCompleted = new EventEmitter<boolean>();
  isSubmitting = false;
  apiError = false;
  passwordMismatchError = false;
  passwordMinLengthError = 'Password must be at least 8 characters.';
  passwordMaxLengthError = 'Password must be fewer than 50 characters.';
  newPasswordForm: PasswordResetForm = new FormGroup({
    newPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(50)])
  });
  hideNewPassword = true;
  hideConfirmPassword = true;
  get passwordMisMatchError(): boolean {
    return this.newPasswordForm.controls.newPassword.touched &&
      this.newPasswordForm.controls.confirmPassword.touched &&
      (this.newPasswordForm.value.newPassword !== this.newPasswordForm.value.confirmPassword);
  }

  constructor(private passwordResetClient: PasswordResetClient) { }

  onSubmit(): void {
    this.isSubmitting = true;
    if (this.passwordMisMatchError) {
      this.isSubmitting = false;
      return;
    }
    this.apiError = false;
    this.passwordResetClient.createNewPassword({ password: this.newPasswordForm.value.newPassword ?? '' })
      .subscribe({
        next: () => this.formCompleted.emit(true),
        error: () => {
          this.apiError = true;
        }
      }).add(() => this.isSubmitting = false);
  }
}

type PasswordResetForm = FormGroup<{
  newPassword: FormControl<string | null>;
  confirmPassword: FormControl<string | null>;
}>;
