<section class="flex w-full h-full p-30">
  <div class="flex flex-col w-full mx-auto max-w-1440 gap-y-30">
    <a routerLink="../overview" class="flex items-center p-5 mr-auto text-sm font-bold uppercase gap-x-5">
      <mat-icon svgIcon="arrow-left" class="mi-18"></mat-icon>
      <span>Back</span>
    </a>
    <section class="flex flex-col gap-y-30">
      <div class="flex gap-x-30">
        <div class="flex flex-col gap-y-10 max-w-640">
          <h2 class="text-xl font-bold">Webinars</h2>          
          <p class="text-lg">
            We have developed a collection of up-to-date webinars to keep you informed on all aspects of your business.
          </p>        
        </div>
      </div>
      <section class="card">
        <app-development-center-wistia-section class="flex flex-col" [model]="wistiaSection"
          [wistiaProject]="wistiaProject"></app-development-center-wistia-section>
      </section>
    </section>
  </div>
</section>