import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordResetClient } from 'src/app/core/clients/generated/client';
import { fadeIn } from 'src/app/shared/constants/animations';

@Component({
  animations: [fadeIn],
  selector: 'app-password-reset-requested',
  templateUrl: './password-reset-requested.component.html',
})
export class PasswordResetRequestedComponent {

  constructor(private passwordResetClient: PasswordResetClient) { }

  formSubmitted = false;
  formSubmitting = false;
  userNameForm = new FormGroup({
    userNameOrEmail: new FormControl('', [Validators.required])
  });

  onSubmit(): void {
    this.userNameForm.markAllAsTouched();
    this.formSubmitting = true;
    if (this.userNameForm.invalid) {
      return;
    }
    const userNameOrEmail = this.userNameForm.value.userNameOrEmail || '';
    this.formSubmitted = true;
    this.passwordResetClient.requestPasswordReset({ userNameOrEmail }).subscribe();
  }
}
