import { Component, Input, OnInit } from '@angular/core';
import { AssetBreakdownModel, PlanningDeskRequestFormGroup } from '../../models/planning-desk.model';
import { AnalysisReportTypeEnum } from '../../enums/analysis-report-type.enum';
import { PortfolioAnalysisCategoryEnum } from '../../enums/portfolio-analysis-category.enum';
import { RecommendationTypeEnum } from '../../enums/recommendation-type.enum';

@Component({
  selector: 'app-planning-desk-review-step',
  templateUrl: './planning-desk-review-step.component.html',
})
export class PlanningDeskReviewStepComponent implements OnInit {
  @Input() parentForm?: PlanningDeskRequestFormGroup;
  assetBreakdown?: AssetBreakdownModel[];
  requestReports?: string;
  recommendationType?: string;
  portfolioAnalysisCategoryEnum = PortfolioAnalysisCategoryEnum;

  private createReportListString(): void {
    const reportTypeOther = this.parentForm?.value.assetDetails.analysisReportTypeOther ? this.parentForm.value.assetDetails.analysisReportTypeOther : null;
    this.requestReports = this.parentForm?.value.assetDetails.analysisReportType.filter(x => x.value !== AnalysisReportTypeEnum.other).map(x => x.name).join(', ');
    if (reportTypeOther)
      this.requestReports += `, ${reportTypeOther}`;
  }

  private createUserRecommendationString(): void {
    if (this.parentForm?.value.generalInfo.recommendationType)
      this.recommendationType = this.parentForm?.value.generalInfo.recommendationType.value === RecommendationTypeEnum.user ? 'User’s recommendation' : 'Alphastar’s recommendation';
  }

  ngOnInit(): void {
    this.createReportListString();
    this.createUserRecommendationString();
  }


}
