import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AllocationFormGroup } from '../models/allocation-form.models';

export const allocationMinimumEntriesValidator = (): ValidatorFn => {
  return (form: AbstractControl): ValidationErrors | null => {
    const newForm = form as AllocationFormGroup;

    //if reallocation is for only making trades, ignore validation
    if (newForm.controls.isTradesOnly && newForm.controls.isTradesOnly.value === true)
      return null;

    //allowing sleeve to be zero in the case they want to move all money to new model
    //const sleeveWithValue = newForm.controls.sleeves?.value?.find(sleeve => sleeve.amount > 0);
    const newSleeveWithValue = newForm.controls.newSleeve?.controls.amount.value > 0 ? true : false;
    const useProtectedCash = newForm.value.useProtectedCash;

    //if ((!sleeveWithValue || (newForm.controls.newSleeve?.controls.showAddNewSleeve.value && !newSleeveWithValue)) && !useProtectedCash) {
    if (((newForm.controls.newSleeve?.controls.showAddNewSleeve.value && !newSleeveWithValue)) && !useProtectedCash) {
      return { noAllocationSet: true };
    } else {
      return null;
    }
  };
};

export const noAllocationSet = 'noAllocationSet';