import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AllocationFormGroup } from '../models/allocation-form.models';

/* Initial amount is a function because valdiators will save the first parameter which causes problems if we start with null values,
 https://stackoverflow.com/questions/46849548/angular-4-custom-validator-with-dynamic-parameter-value */
export const allocationAmountEqualValidator = (initialAmount: () => number|undefined): ValidatorFn => {
  return (form: AbstractControl): ValidationErrors | null => {
    const newForm = form as AllocationFormGroup;

    //if reallocation is for only making trades, ignore validation
    if (newForm.controls.isTradesOnly && newForm.controls.isTradesOnly.value === true)
      return null;

    let sleeveValue = newForm?.controls.sleeves?.value.reduce((acc, current) => acc + current.amount, 0) || 0;
    sleeveValue = sleeveValue >= 0 ? Number(sleeveValue.toFixed(2)) : 0;
    let newSleeveValue = newForm?.controls.newSleeve?.controls?.amount?.value as number || 0;
    newSleeveValue = newSleeveValue >= 0 ? Number(newSleeveValue.toFixed(2)) : 0;
    const protectedCashAmount = newForm.value.protectedCashAmount?.amount || 0;
    const amount = Number(initialAmount()?.toFixed(2));
    const isNotEqual = Number((sleeveValue + newSleeveValue + protectedCashAmount).toFixed(2)) !== amount;

    if (isNotEqual) {
      return { allocationAmountNotEqual: true };
    } else {
      return null;
    }
  };
};

export const allocationAmountNotEqual = 'allocationAmountNotEqual';