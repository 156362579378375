import { TableHeader } from 'src/app/shared/helpers/table.helpers';
import { Account } from './account.models';

export const AccountTableHeaders: TableHeader<Account>[] = [
  {
    DisplayName: 'Advisor',
    ColumnName: ['Advisor'],
    WidthClass: 'min-w-150 w-150',
    CompareFn: (a: Account, b: Account, order: 1|-1):number => {
      const valA = a.Advisors ? a.Advisors.map(adv => (adv.FirstName ?? '').concat((adv.LastName ?? ''))).join(',') : '';
      const valB = b.Advisors ? b.Advisors.map(adv => (adv.FirstName ?? '').concat(adv.LastName ?? '')).join(',') : '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Clients',
    ColumnName: ['Clients'],
    WidthClass: 'min-w-128 w-128',
    CompareFn: (a: Account, b: Account, order: 1|-1):number => {
      const valA = a.Clients ? a.Clients.map(c => (c.LastName ?? '').concat(c.FirstName ?? '')).join(',') : '';
      const valB = b.Clients ? b.Clients.map(c => (c.LastName ?? '').concat(c.FirstName ?? '')).join(',') : '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Type',
    ColumnName: ['Type'],
    WidthClass: 'min-w-128 w-128',
  },
  {
    DisplayName: 'Model/Product',
    ColumnName: ['Model', 'Product'],
    WidthClass: 'min-w-240 w-240',
    CompareFn: (a: Account, b: Account, order: 1|-1):number => {
      const valA = a.IsInvestmentAccount ? (a.Custodian ?? '').concat(a.Model ?? '') : (a.Carrier ?? '').concat(a.Product ?? '');
      const valB = b.IsInvestmentAccount ? (b.Custodian ?? '').concat(b.Model ?? '') : (b.Carrier ?? '').concat(b.Product ?? '');
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Value/Premium',
    ColumnName: ['Value'],
    WidthClass: 'min-w-96 w-96',
    CompareFn: (a: Account, b: Account, order: 1|-1):number => {
      const valA = a.Value;
      const valB = b.Value;
      if (valA === valB) return 0;
      if (valA === null) return -1 * order;
      if (valB === null) return 1 * order;
      return (valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Opened/Inforce Date',
    ColumnName: ['OpenedDate'],
    WidthClass: 'min-w-120 w-120',
    CompareFn: (a: Account, b: Account, order: 1|-1):number => {
      const valA = a.OpenedDate;
      const valB = b.OpenedDate;
      if (valA === valB) return 0;
      if (valA === null) return -1 * order;
      if (valB === null) return 1 * order;
      return (valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Client Fee',
    ColumnName: ['Fee'],
    WidthClass: 'min-w-60 w-60',
    CompareFn: (a: Account, b: Account, order: 1|-1):number => {
      const valA = a.Fee;
      const valB = b.Fee;
      if (valA === valB) return 0;
      if (valA === null) return -1 * order;
      if (valB === null) return 1 * order;
      return (valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Account Status',
    ColumnName: ['Status'],
    WidthClass: 'min-w-128 w-128',
  },
];