import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from 'src/app/shared/shared.module';
import { ClientsModule } from 'src/app/core/clients/clients.module';
import { MfaVerificationFormComponent } from './components/mfa-verification-form/mfa-verification-form.component';
import { MfaVerificationPageComponent } from './pages/mfa-verification-page/mfa-verification-page.component';
import { FirstMfaSetupPageComponent } from './pages/first-mfa-setup-page/first-mfa-setup-page.component';
import { FirstMfaSetupFormComponent } from './components/first-mfa-setup-form/first-mfa-setup-form.component';
import { FirstMfaVerificationFormComponent } from './components/first-mfa-verification-form/first-mfa-verification-form.component';
import { FirstMfaVerificationPageComponent } from './pages/first-mfa-verification-page/first-mfa-verification-page.component';
import { SignupPageComponent } from './pages/signup-page/signup-page.component';
import { SignupFormComponent } from './components/signup-form/signup-form.component';
import { PasswordResetRequestedPageComponent } from './pages/password-reset-requested-page/password-reset-requested-page.component';
import { PasswordResetRequestedComponent } from './components/password-reset-requested/password-reset-requested.component';
import { CreateNewPasswordPageComponent } from './pages/create-new-password-page/create-new-password-page.component';
import { PasswordResetFormComponent } from './components/password-reset-form/password-reset-form.component';


@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    LoginFormComponent,
    LoginPageComponent,
    MfaVerificationFormComponent,
    MfaVerificationPageComponent,
    FirstMfaSetupPageComponent,
    FirstMfaSetupFormComponent,
    FirstMfaVerificationFormComponent,
    FirstMfaVerificationPageComponent,
    SignupPageComponent,
    SignupFormComponent,
    PasswordResetRequestedPageComponent,
    PasswordResetRequestedComponent,
    CreateNewPasswordPageComponent,
    PasswordResetFormComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserModule,
    SharedModule,
    ClientsModule,
  ]
})
export class AuthModule { }
